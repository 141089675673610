import React from "react";

import { Box, Typography } from "@material-ui/core";
import Highcharts from "highcharts";

import MagicQuadrant from "components/visuals/MagicQuadrant";
import useColourPalette from "components/visuals/useColourPalette";
import { useAppSelector } from "store";
import {
    selectHasErrors,
    selectIsLoading,
    selectSpendPerHeadAndPopulationPerSupergroup
} from "modules/customer/tools/location/spendNew/spendSlice";
import { median } from "mathjs";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

const SpendPerHead: React.FC = () => {
    const colourPalette = useColourPalette();
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const spendPerHeadPerSupergroup = useAppSelector(selectSpendPerHeadAndPopulationPerSupergroup);
    const useQuadrants = spendPerHeadPerSupergroup.length > 1;

    const xMidPoint = useQuadrants ? median(spendPerHeadPerSupergroup.map(item => item.population)) : 0;
    const yMidPoint = useQuadrants ? median(spendPerHeadPerSupergroup.map(item => item.spendPerHead)) : 0;
    const options: Highcharts.Options = {
        chart: {
            height: 450
        },
        xAxis: {
            title: {
                text: "Population size"
            },
            labels: {
                enabled: false
            },
        },
        yAxis: {
            title: {
                text: "Spend per head"
            },
            labels: {
                enabled: false
            },
        },
        series: [{
            type: "scatter",
            label: {enabled: false},
            data: spendPerHeadPerSupergroup.map(item => ({
                name: item.supergroupName,
                x: item.population,
                y: item.spendPerHead,
                color: colourPalette.categorical[item.supergroupCode - 1],
            }))
        }],
        legend: {enabled: false},
        tooltip: {
            useHTML: true,
            headerFormat: '<table>',
            // @ts-ignore
            pointFormatter: function () {

                const valueFormattingArr = [
                    `color:${this.color};font-weight:bold`,
                    `color:${this.color};font-weight:bold`
                ];
                return stringUtils.tooltipHTML(["Spend per head", "Population"], {
                    header: this.name,
                    values: [
                        numberFormatter.toGBP(this.y, 1),
                        numberFormatter.toDecimalPlaces(this.x, 1)
                    ],
                    valueFormattingArr
                });
            },
            footerFormat: '</table>'
        }
    };

    return (
        <Box data-cy="spend-per-head">
            <Typography variant="h6" component="div" gutterBottom>
                Spend per head and population size of each customer profile
            </Typography>
            <MagicQuadrant
                loading={isLoading}
                error={hasErrors}
                options={options}
                topLeftLabel="Small population, high spend"
                topRightLabel="Large population, high spend"
                bottomLeftLabel="Small population, low spend"
                bottomRightLabel="Large population, low spend"
                doQuadrants={useQuadrants}
                xMidPoint={xMidPoint}
                yMidPoint={yMidPoint}
                xAxisReversed={false}
                padBelowZero={true}
            />
        </Box>
    );
};

export default SpendPerHead;
