import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { median } from "mathjs";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { DataWrapper } from "domain/dataWrapper";
import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import {
    selectCatchmentSpendTotals,
    selectComparator,
    selectDirectCompetitorNames,
    selectStore
} from "modules/customer/insights/portfolioNew/portfolioSlice";
import { Store } from "modules/customer/insights/portfolioNew/store";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";
import mathUtils from "utils/mathUtils";

import { Competitor, loadCompetitors } from "./competitors";
import { CoreRetailHub, loadCoreRetailHubs } from "./coreRetailHub";
import { LocalSupply, loadLocalSupply } from "./localSupply";

interface CompetitionState {
    isLoading: boolean,
    hasErrors: boolean,
    coreRetailHubs: CoreRetailHub[] | undefined,
    competitors: Competitor[] | undefined,
    localSupply: LocalSupply[] | undefined
}

interface LoadCompetitionResponse {
    coreRetailHubs: CoreRetailHub[] | undefined,
    competitors: Competitor[] | undefined,
    localSupply: LocalSupply[] | undefined
}

const initialState: CompetitionState = {
    isLoading: false,
    hasErrors: false,
    coreRetailHubs: [],
    competitors: [],
    localSupply: []
};

const competitionSlice = createSlice({
    name: "customer/insights/portfolioNew/competition",
    initialState,
    reducers: {
        clearCompetitors: (state) => {
            state.competitors = initialState.competitors;
        },
        clearCoreRetailHubs: (state) => {
            state.coreRetailHubs = initialState.coreRetailHubs;
        },
        clearLocalSupply: (state) => {
            state.localSupply = initialState.localSupply;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCompetition.pending, (state: CompetitionState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadCompetition.rejected, (state: CompetitionState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.coreRetailHubs = initialState.coreRetailHubs;
            state.competitors = initialState.competitors;
            state.localSupply = initialState.localSupply;
        });
        builder.addCase(loadCompetition.fulfilled, (state: CompetitionState, action: PayloadAction<LoadCompetitionResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.coreRetailHubs = action.payload.coreRetailHubs;
            state.competitors = action.payload.competitors;
            state.localSupply = action.payload.localSupply;
        });
    }
});

export const loadCompetition = createAppAsyncThunk(
    "customer/insights/portfolioNew/competition/loadCompetition",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const store = selectStore(state);
            const comparator = selectComparator(state);
            const comparatorStores = comparator?.getStores();
            const directCompetitorNames = selectDirectCompetitorNames(state);

            let stores: Store[] = [];

            if (store && comparatorStores) {
                stores.push(store);
                comparatorStores.forEach((store) => {
                    stores.push(store);
                });
            }

            const coreRetailHubsPromise = thunkAPI.dispatch(loadCoreRetailHubs(stores));
            const competitorsPromise = thunkAPI.dispatch(loadCompetitors(stores, directCompetitorNames));
            const localSupplyPromise = thunkAPI.dispatch(loadLocalSupply(stores));

            const results = await Promise.all([coreRetailHubsPromise, competitorsPromise, localSupplyPromise]);

            const loadCompetitionResponse: LoadCompetitionResponse = {
                coreRetailHubs: results[0],
                competitors: results[1],
                localSupply: results[2]
            };

            return loadCompetitionResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Competition.", error));
            return thunkAPI.rejectWithValue([]);
        }
    }
);

export const clearCompetition = (): AppThunk => (dispatch) => {
    dispatch(competitionSlice.actions.clearCompetitors());
    dispatch(competitionSlice.actions.clearCoreRetailHubs());
    dispatch(competitionSlice.actions.clearLocalSupply());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.portfolioNew.competition.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.portfolioNew.competition.hasErrors;
};

export const selectCoreRetailHubs = (state: RootState) => {
    return state.customer.insights.portfolioNew.competition.coreRetailHubs;
};

export const selectCompetitors = (state: RootState) => {
    return state.customer.insights.portfolioNew.competition.competitors;
};

export const selectLocalSupply = (state: RootState) => {
    return state.customer.insights.portfolioNew.competition.localSupply;
};

export const selectSelectedStoreCoreRetailHub = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectCoreRetailHubs(state),
    (selectedStore, coreRetailHubs) => {

        if (!selectedStore || !coreRetailHubs) {
            return undefined;
        }

        const selectedStoreCoreRetailHub = coreRetailHubs?.find(retailHub => retailHub.localArea === selectedStore.outputAreaCode);

        return selectedStoreCoreRetailHub;
    }
);

export const selectSelectedStoreCompetitorsWithin5Kms = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectCompetitors(state),
    (selectedStore, competitors) => {

        if (!selectedStore || !competitors) {
            return undefined;
        }

        const selectedStoreCompetitors = competitors?.filter(competitor => competitor.storeId === selectedStore.id);

        let selectedStoreCompetitorsWithin5Kms: Competitor[] = [];

        selectedStoreCompetitors.forEach(store => {
            const distance = mathUtils.haversineDistance(
                selectedStore.latitude,
                selectedStore.longitude,
                store.latitude,
                store.longitude
            );

            if (distance <= 5) {
                selectedStoreCompetitorsWithin5Kms.push(store);
            }
        });

        return selectedStoreCompetitorsWithin5Kms;
    }
);

export const selectDistanceToCoreRetailHub = createSelector(
    selectIsLoading,
    selectHasErrors,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectCoreRetailHubs(state),
    (isLoading, hasErrors, selectedStore, selectedComparator, coreRetailHubs) => {
        interface DistanceToCoreRetailHub {
            selectedStoreDistance: number
            selectedComparatorDistance: number
        }

        const distanceToCoreRetailHub: DataWrapper<DistanceToCoreRetailHub> = {
            isLoading: isLoading,
            hasErrors: hasErrors,
            data: { selectedStoreDistance: 0, selectedComparatorDistance: 0 }
        };

        if (!selectedStore || distanceToCoreRetailHub.hasErrors || distanceToCoreRetailHub.isLoading) {
            return distanceToCoreRetailHub;
        }

        const selectedStoreCoreRetailHub = coreRetailHubs?.find(retailHub => retailHub.localArea === selectedStore.outputAreaCode);

        if (selectedStore.latitude &&
            selectedStore.longitude &&
            selectedStoreCoreRetailHub?.latitude &&
            selectedStoreCoreRetailHub?.longitude) {
            distanceToCoreRetailHub.data.selectedStoreDistance = mathUtils.haversineDistance(
                selectedStore.latitude,
                selectedStore.longitude,
                selectedStoreCoreRetailHub.latitude,
                selectedStoreCoreRetailHub.longitude
            );
        } else {
            distanceToCoreRetailHub.data.selectedStoreDistance = 0;
        }

        const comparatorStores = selectedComparator?.getStores();
        let comparatorStoresDistanceToCoreRetailHub = [];

        if (comparatorStores && coreRetailHubs) {
            comparatorStores.forEach(store => {
                const coreRetailHub = coreRetailHubs.find(retailHub => retailHub.localArea === store.outputAreaCode);
                if (coreRetailHub) {
                    comparatorStoresDistanceToCoreRetailHub.push(
                        mathUtils.haversineDistance(
                            store.latitude,
                            store.longitude,
                            coreRetailHub.latitude,
                            coreRetailHub.longitude
                        )
                    );
                } else {
                    comparatorStoresDistanceToCoreRetailHub.push(0);
                }
            });
        } else {
            comparatorStoresDistanceToCoreRetailHub.push(0);
        }

        distanceToCoreRetailHub.data.selectedComparatorDistance = median(comparatorStoresDistanceToCoreRetailHub);

        return distanceToCoreRetailHub;
    }
);

export const selectNumberOfCompetitors = createSelector(
    selectIsLoading,
    selectHasErrors,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectCompetitors(state),
    (isLoading, hasErrors, selectedStore, selectedComparator, competitors) => {
        interface NumberOfCompetitors {
            selectedStoreNumberOfDirectCompetitors: number
            selectedStoreNumberOfCompetitors: number
            selectedComparatorNumberOfDirectCompetitors: number
            selectedComparatorNumberOfCompetitors: number
        }

        const numberOfCompetitors: DataWrapper<NumberOfCompetitors> = {
            isLoading: isLoading,
            hasErrors: hasErrors,
            data: {
                selectedStoreNumberOfDirectCompetitors: 0,
                selectedStoreNumberOfCompetitors: 0,
                selectedComparatorNumberOfDirectCompetitors: 0,
                selectedComparatorNumberOfCompetitors: 0
            }
        };

        if (!selectedStore || numberOfCompetitors.hasErrors || numberOfCompetitors.isLoading) {
            return numberOfCompetitors;
        }

        const selectedStoreCompetitors = competitors?.filter(competitor => competitor.storeId === selectedStore.id);

        if (selectedStore.latitude &&
            selectedStore.longitude) {
            const storesWithin5Kms: Competitor[] = [];

            selectedStoreCompetitors?.forEach(store => {
                const distance = mathUtils.haversineDistance(
                    selectedStore.latitude,
                    selectedStore.longitude,
                    store.latitude,
                    store.longitude
                );

                if (distance <= 5) {
                    storesWithin5Kms.push(store);
                }
            });

            numberOfCompetitors.data.selectedStoreNumberOfCompetitors = storesWithin5Kms.length;
            numberOfCompetitors.data.selectedStoreNumberOfDirectCompetitors = storesWithin5Kms.filter(store => store.directCompetitor === true).length;
        } else {
            numberOfCompetitors.data.selectedStoreNumberOfCompetitors = 0;
            numberOfCompetitors.data.selectedStoreNumberOfDirectCompetitors = 0;
        }

        const selectedComparatorStores = selectedComparator?.getStores();
        const selectedComparatorStoresIds = selectedComparatorStores?.map(store => store.id);
        const selectedComparatorStoresCompetitors = competitors?.filter(competitor => selectedComparatorStoresIds?.includes(competitor.storeId));
        const selectedComparatorStoresNumberOfCompetitors = _(selectedComparatorStoresCompetitors).groupBy(competitor => competitor.storeId)
            .map((group, key) => {
                const storeId = key;

                const comparatorStore = selectedComparatorStores?.find(store => store.id === storeId);

                const storesWithin5Kms: Competitor[] = [];
                group.forEach(store => {
                    let distance = 0;

                    if (comparatorStore?.latitude && comparatorStore.longitude) {
                        distance = mathUtils.haversineDistance(
                            comparatorStore.latitude,
                            comparatorStore.longitude,
                            store.latitude,
                            store.longitude
                        );
                    }

                    if (distance <= 5) {
                        storesWithin5Kms.push(store);
                    }
                });

                const numberOfCompetitors = storesWithin5Kms.length;
                const numberOfDirectCompetitors = storesWithin5Kms.filter(store => store.directCompetitor === true).length;
                return {
                    storeId,
                    numberOfCompetitors,
                    numberOfDirectCompetitors
                };
            })
            .value();

        if (selectedComparatorStoresNumberOfCompetitors.length > 0) {
            numberOfCompetitors.data.selectedComparatorNumberOfCompetitors = median(selectedComparatorStoresNumberOfCompetitors.map(store => store.numberOfCompetitors));
            numberOfCompetitors.data.selectedComparatorNumberOfDirectCompetitors = median(selectedComparatorStoresNumberOfCompetitors.map(store => store.numberOfDirectCompetitors));
        } else {
            numberOfCompetitors.data.selectedComparatorNumberOfCompetitors = 0;
            numberOfCompetitors.data.selectedComparatorNumberOfDirectCompetitors = 0;
        }

        return numberOfCompetitors;
    }
);

export const selectDistanceToHotspot = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSelectedStoreCoreRetailHub,
    selectSelectedStoreCompetitorsWithin5Kms,
    (state: RootState) => selectStore(state),
    (isLoading, hasErrors, coreRetailHub, competitors, selectedStore) => {
        const id = "distance-to-hotspot";
        const label = "Distance to hotspot";


        let ragStatus = RagIndicatorStatus.Info;
        let ragValue = "";

        if (isLoading || hasErrors) {
            return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
        }

        const selectedStoreDistanceToHotspot = mathUtils.haversineDistance(
            selectedStore?.latitude ?? 0,
            selectedStore?.longitude ?? 0,
            coreRetailHub?.latitude ?? 0,
            coreRetailHub?.longitude ?? 0
        );

        const directCompetitors = competitors?.filter(store => store.directCompetitor === true) ?? [];

        if (directCompetitors?.length <= 0) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = `No direct competitors in the local area of ${selectedStore?.name}`;

            return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
        }

        let closerCompetitors = 0, furtherCompetitors = 0;

        directCompetitors.forEach(store => {
            const distance = mathUtils.haversineDistance(
                store.latitude ?? 0,
                store.longitude ?? 0,
                coreRetailHub?.latitude ?? 0,
                coreRetailHub?.longitude ?? 0
            );

            if (distance < selectedStoreDistanceToHotspot) {
                closerCompetitors++;
            } else if (distance > selectedStoreDistanceToHotspot) {
                furtherCompetitors++;
            }
        });

        const percentCloser = 100 * (closerCompetitors / directCompetitors.length);
        const percentFurther = 100 * (furtherCompetitors / directCompetitors.length);

        if (percentFurther >= 75) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = `Majority of direct competitors are further from the hotspot of the local area than your ${selectedStore?.name} store`;
        } else if (percentCloser >= 75) {
            ragStatus = RagIndicatorStatus.Red;
            ragValue = `Majority of direct competitors are closer to the hotspot of the local area than your ${selectedStore?.name} store`;
        } else {
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = `${selectedStore?.name} is broadly in line with the distance from the hotspot as other direct competitors in the local area`;
        }

        return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
    }
);

export const selectSizeRelativeToDirectCompetitors = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSelectedStoreCompetitorsWithin5Kms,
    (state: RootState) => selectStore(state),
    (isLoading, hasErrors, competitors, selectedStore) => {
        const id = "size-relative-to-direct-competitors";
        const label = "Size relative to direct competitors";

        const selectedStoreSqft = selectedStore?.sizeInSquareFeet ?? 0;
        const directCompetitors = competitors?.filter(store => store.directCompetitor === true) ?? [];

        let ragStatus = RagIndicatorStatus.Info;
        let ragValue = "";

        if (isLoading || hasErrors) {
            return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
        }

        if (directCompetitors?.length <= 0) {
            ragStatus = RagIndicatorStatus.Info;
            ragValue = `No direct competitors in the local area of ${selectedStore?.name}`;

            return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
        }

        let smallerCompetitors = 0, largerCompetitors = 0;

        directCompetitors.forEach(store => {
            const size = store.size;

            if (size < selectedStoreSqft) {
                smallerCompetitors++;
            } else if (size > selectedStoreSqft) {
                largerCompetitors++;
            }
        });

        const percentSmaller = 100 * (smallerCompetitors / directCompetitors.length);
        const percentLarger = 100 * (largerCompetitors / directCompetitors.length);

        if (percentSmaller >= 75) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = `Majority of direct competitors are smaller in size than your ${selectedStore?.name} store`;
        } else if (percentLarger >= 75) {
            ragStatus = RagIndicatorStatus.Red;
            ragValue = `Majority of direct competitors are larger in size than your ${selectedStore?.name} store`;
        } else {
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = `Majority of direct competitors are broadly the same size as your ${selectedStore?.name} store`;
        }

        return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
    }
);

export const selectLocalDemandAndSupply = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectLocalSupply,
    (state: RootState) => selectCatchmentSpendTotals(state),
    (isLoading, hasErrors, localSupply, catchmentSpendTotals) => {
        const localDemand: DataWrapper<{ storeId: string, demand: number, supply: number }[]> = {
            isLoading: isLoading || catchmentSpendTotals.isLoading,
            hasErrors: hasErrors || catchmentSpendTotals.hasErrors,
            data: []
        };

        if (!localSupply || !catchmentSpendTotals || localDemand.hasErrors || localDemand.isLoading) {
            return localDemand;
        }

        const result = _(catchmentSpendTotals.data)
            .groupBy(item => item.storeID)
            .map((group, key) => ({
                storeId: key,
                demand: group.reduce((accumulator, item) => {
                    const spend = item.weightedSpend;
                    return accumulator + spend;
                }, 0),
                supply: localSupply?.find(store => store.storeId === key)?.storeCount ?? 0
            }))
            .value();

        localDemand.data = result;

        return localDemand;
    }
);

export const selectSupplyAndDemandCategorisation = createSelector(
    selectIsLoading,
    selectHasErrors,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectLocalDemandAndSupply(state),
    (isLoading, hasErrors, selectedStore, selectedComparator, supplyAndDemand) => {
        const id = "supply-and-demand-categorisation";
        const label = "Supply and demand";

        const store = supplyAndDemand.data.find(store => store.storeId === selectedStore?.id);
        const comparator = supplyAndDemand.data.filter(store => store.storeId !== selectedStore?.id);

        let ragStatus = RagIndicatorStatus.Info;
        let ragValue = "";

        if (supplyAndDemand.isLoading || supplyAndDemand.hasErrors || !store || comparator.length === 0) {
            return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
        }

        const comparatorMedianSupply = comparator.length > 0 ? median(comparator.map(item => item.supply || 0)) : 0;
        const comparatorMedianDemand = comparator.length > 0 ? median(comparator.map(item => item.demand || 0)) : 0;

        const storeSupply = store?.supply ?? 0;
        const storeDemand = store?.demand ?? 0;

        if (storeSupply <= comparatorMedianSupply && storeDemand > comparatorMedianDemand) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = `${selectedStore?.name} is positioned in an area of low supply and high demand relative to ${selectedComparator?.name} median`;
        } else if (storeSupply <= comparatorMedianSupply && storeDemand <= comparatorMedianDemand) {
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = `${selectedStore?.name} is positioned in an area of low supply and low demand relative to ${selectedComparator?.name} median`;
        } else if (storeSupply > comparatorMedianSupply && storeDemand > comparatorMedianDemand) {
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = `${selectedStore?.name} is positioned in an area of high supply and high demand relative to ${selectedComparator?.name} median`;
        } else {
            ragStatus = RagIndicatorStatus.Red;
            ragValue = `${selectedStore?.name} is positioned in an area of high supply and low demand relative to ${selectedComparator?.name} median`;
        }

        return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
    }
);

export default competitionSlice;
