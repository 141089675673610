import { createSelector, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { DateTime } from "luxon";
import { median } from "mathjs";

import { DataWrapper } from "domain/dataWrapper";
import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import {
    selectComparator,
    selectCostReferenceDate,
    selectMonthlySales,
    selectStore,
    selectYearlyCosts
} from "modules/customer/insights/portfolioNew/portfolioSlice";
import { RootState } from "store";
import dateUtils from "utils/dateUtils";
import mathUtils from "utils/mathUtils";

interface ProfitState {
    isLoading: boolean,
    hasErrors: boolean
}

const initialState: ProfitState = {
    isLoading: false,
    hasErrors: false
};

const profitSlice = createSlice({
    name: "customer/insights/portfolioNew/profit",
    initialState,
    reducers: {}
});

const safeProfitMargin = (sales: number, costs: number): number => {
    if (sales === 0) {
        if (costs === 0) {
            return 0;
        }
        return -100;
    }
    return 100 * ((sales - costs) / sales);
};

export const selectYearlyGrossProfit = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectMonthlySales(state),
    (selectedStore, selectedComparator, monthlySales) => {
        interface YearlyGrossProfit {
            selectedStoreYearlyProfit: number,
            selectedComparatorYearlyProfit: number
        }

        const yearlyGrossProfit: DataWrapper<YearlyGrossProfit> = {
            isLoading: monthlySales.isLoading,
            hasErrors: monthlySales.hasErrors,
            data: { selectedStoreYearlyProfit: 0, selectedComparatorYearlyProfit: 0 }
        };
        if (!selectedStore || yearlyGrossProfit.hasErrors || yearlyGrossProfit.isLoading) {
            return yearlyGrossProfit;
        }

        const selectedStoreMonthlySales = monthlySales.data.filter(sales => sales.storeID === selectedStore.id);
        yearlyGrossProfit.data.selectedStoreYearlyProfit = selectedStoreMonthlySales.filter(sales => sales.yearsPrior === 0)
            .reduce((total, current) => total + (current.totalSales - current.totalCostOfGoods), 0);

        const comparatorStoresIDs = selectedComparator?.getStores().map(store => store.id);
        const selectedComparatorsMonthlySales = monthlySales.data.filter(sales => comparatorStoresIDs?.includes(sales.storeID));
        const selectedComparatorYearlyProfit = _(selectedComparatorsMonthlySales).filter(sales => sales.yearsPrior === 0)
            .groupBy(selectedComparatorsMonthlySales => selectedComparatorsMonthlySales.storeID)
            .map((group, key) => ({
                storeId: key,
                yearlyGrossProfitValues: group.reduce((total, current) => total + (current.totalSales - current.totalCostOfGoods), 0)
            }))
            .value();

        const comparatorYearlyProfitValues = selectedComparatorYearlyProfit.map(x => x.yearlyGrossProfitValues);
        yearlyGrossProfit.data.selectedComparatorYearlyProfit = comparatorYearlyProfitValues.length === 0 ? 0 : median(comparatorYearlyProfitValues);

        return yearlyGrossProfit;
    }
);

export const selectYearlyGrossProfitMargin = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectMonthlySales(state),
    (store, comparator, monthlySales) => {
        interface YearlyGrossProfitMargin {
            store: number,
            comparator: number
        }

        const yearlyGrossProfitMargin: DataWrapper<YearlyGrossProfitMargin> = {
            isLoading: monthlySales.isLoading,
            hasErrors: monthlySales.hasErrors,
            data: {
                store: 0,
                comparator: 0
            }
        };
        if (!store || yearlyGrossProfitMargin.hasErrors || yearlyGrossProfitMargin.isLoading) {
            return yearlyGrossProfitMargin;
        }

        const { storeTotalSales, storeTotalCostOfGoods } = monthlySales.data
            .filter(sales => sales.storeID === store.id && sales.yearsPrior === 0)
            .reduce((accumulator, sales) => {
                accumulator.storeTotalSales += sales.totalSales;
                accumulator.storeTotalCostOfGoods += sales.totalCostOfGoods;
                return accumulator;
            }, {
                storeTotalSales: 0,
                storeTotalCostOfGoods: 0
            });
        const storeGrossProfitMargin = (storeTotalSales === 0) ? 0 : 100 * ((storeTotalSales - storeTotalCostOfGoods) / storeTotalSales);
        yearlyGrossProfitMargin.data.store = storeGrossProfitMargin;

        const comparatorStoresIds = comparator?.getStores().map(store => store.id) ?? [];
        const { comparatorTotalSales, comparatorTotalCostOfGoods } = _(monthlySales.data)
            .filter(sales => comparatorStoresIds.includes(sales.storeID) && sales.yearsPrior === 0)
            .groupBy(sales => sales.storeID)
            .map((group, key) => ({
                storeId: key,
                values: group.reduce((accumulator, sales) => {
                    accumulator.totalSales += sales.totalSales;
                    accumulator.totalCostOfGoods += sales.totalCostOfGoods;
                    return accumulator;
                }, {
                    totalSales: 0,
                    totalCostOfGoods: 0
                })
            }))
            .value()
            .reduce((accumulator, groupedSales) => {
                accumulator.comparatorTotalSales.push(groupedSales.values.totalSales);
                accumulator.comparatorTotalCostOfGoods.push(groupedSales.values.totalCostOfGoods);
                return accumulator;
            }, {
                comparatorTotalSales: [] as number[],
                comparatorTotalCostOfGoods: [] as number[]
            });
        const medianComparatorTotalSales = (comparatorTotalSales.length === 0) ? 0 : median(comparatorTotalSales);
        const medianComparatorTotalCostOfGoods = (comparatorTotalCostOfGoods.length === 0) ? 0 : median(comparatorTotalCostOfGoods);
        const comparatorGrossProfitMargin = (medianComparatorTotalSales === 0) ? 0 : 100 * ((medianComparatorTotalSales - medianComparatorTotalCostOfGoods) / medianComparatorTotalSales);
        yearlyGrossProfitMargin.data.comparator = comparatorGrossProfitMargin;

        return yearlyGrossProfitMargin;
    }
);

export const selectGrossProfitMarginOverTime = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectMonthlySales(state),
    (selectedStore, selectedComparator, monthlySales) => {
        interface GrossProfitMarginOverTimeValues {
            date: Date
            sales: number
            costs: number
            profit: number
        }

        interface GrossProfitMarginOverTime {
            store: GrossProfitMarginOverTimeValues[]
            comparator: GrossProfitMarginOverTimeValues[]
        }

        const grossProfitMarginOverTime: DataWrapper<GrossProfitMarginOverTime> = {
            isLoading: monthlySales.isLoading,
            hasErrors: monthlySales.hasErrors,
            data: { store: [], comparator: [] }
        };
        if (!selectedStore || grossProfitMarginOverTime.hasErrors || grossProfitMarginOverTime.isLoading) {
            return grossProfitMarginOverTime;
        }

        // Store data
        const selectedStoreMonthlySales = monthlySales.data.filter(sales => sales.storeID === selectedStore.id);
        grossProfitMarginOverTime.data.store = _(selectedStoreMonthlySales).filter(sales => sales.yearsPrior === 0)
            .groupBy(selectedStoreMonthlySales => selectedStoreMonthlySales.monthStartDate)
            .map((group, key) => {
                const sales = group.reduce((total, current) => total + current.totalSales, 0);
                const costs = group.reduce((total, current) => total + current.totalCostOfGoods, 0);
                return {
                    date: dateUtils.dateUTC(key),
                    sales,
                    costs,
                    profit: safeProfitMargin(sales, costs)
                };
            })
            .sortBy(x => x.date)
            .value();

        // Comparator data
        const comparatorStoreIDs = selectedComparator?.getStores().map(store => store.id) ?? [];
        const comparatorMonthlySales = monthlySales.data.filter(sales => comparatorStoreIDs.includes(sales.storeID));

        grossProfitMarginOverTime.data.comparator = _(comparatorMonthlySales)
            .filter(sales => sales.yearsPrior === 0)
            .groupBy(sales => sales.monthStartDate)
            .map((group, key) => {
                const sales = median(group.map(item => item.totalSales));
                const profit = median(group.map(item => safeProfitMargin(item.totalSales, item.totalCostOfGoods)));
                const costs = (((profit * sales) / 100) - sales) * -1; //Calculating comparator cost value to display in tooltip
                return {
                    date: dateUtils.dateUTC(key),
                    sales,
                    costs,
                    profit
                };

            })
            .sortBy(x => x.date)
            .value();

        return grossProfitMarginOverTime;
    }
);

export const selectGrossProfitTrend = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectMonthlySales(state),
    (selectedStore, monthlySales) => {
        const id = "gross-profit-trend";
        let label = "Gross profit trend";

        const selectedStoreMonthlySales = monthlySales.data.filter(sales => sales.storeID === selectedStore?.id);
        const selectedStoreTotalSales = _(selectedStoreMonthlySales).filter(sales => sales.yearsPrior === 0)
            .groupBy(selectedStoreMonthlySales => selectedStoreMonthlySales.monthStartDate)
            .map((group, key) => ({
                month: key,
                totalSales: group.reduce((total, current) => total + current.totalSales, 0)
            }))
            .sortBy(x => x.month)
            .value();
        const selectedStoreTotalCogs = _(selectedStoreMonthlySales).filter(sales => sales.yearsPrior === 0)
            .groupBy(selectedStoreMonthlySales => selectedStoreMonthlySales.monthStartDate)
            .map((group, key) => ({
                month: key,
                totalCogs: group.reduce((total, current) => total + current.totalCostOfGoods, 0)
            }))
            .sortBy(x => x.month)
            .value();

        const selectedStoreProfit = [] as number[];
        for (let i = 0; i < selectedStoreTotalSales.length; i++) {
            const profit = mathUtils.safePercentageChange(selectedStoreTotalSales[i].totalSales, selectedStoreTotalCogs[i].totalCogs);
            selectedStoreProfit.push(profit);
        }

        const arrayLength = selectedStoreProfit.length;
        const numberOfMonths = arrayLength;
        const latestPeriodProfit = selectedStoreProfit[arrayLength - 1];
        const comparisonPeriodProfit = selectedStoreProfit[0];

        let cmgrValue = 0;
        if (comparisonPeriodProfit === 0 && latestPeriodProfit > 0) {
            cmgrValue = 0.6;
        } else {
            const cmgrCalculation = ((latestPeriodProfit / comparisonPeriodProfit) ** (1 / numberOfMonths) - 1);
            cmgrValue = cmgrCalculation * 100;
        }

        let ragStatus;
        let ragValue;

        //RAG indicator:
        if (cmgrValue > 0.5) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = "Gross profit trended upward";
        } else if (cmgrValue >= 0) {
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = "Gross profit trend is stagnant";
        } else {
            ragStatus = RagIndicatorStatus.Red;
            ragValue = "Gross profit trended downward";
        }

        return new RagIndicator(id, ragStatus, label, ragValue, monthlySales.isLoading, monthlySales.hasErrors);
    }
);

// Chapter 3.2
export interface RankedStoreByProfit {
    rank: number,
    isSelected: boolean,
    storeName: string,
    value: number
}

export interface RollingGrossProfit {
    rankedStores: RankedStoreByProfit[]
}

export const selectRankedGrossProfit = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectMonthlySales(state),
    (selectedStore, selectedComparator, monthlySales) => {
        const rankedGrossProfit: DataWrapper<RollingGrossProfit> = {
            isLoading: monthlySales.isLoading,
            hasErrors: monthlySales.hasErrors,
            data: { rankedStores: [] }
        };

        if (!selectedStore || rankedGrossProfit.hasErrors || rankedGrossProfit.isLoading) {
            return rankedGrossProfit;
        }

        const selectedStoreMonthlySales = monthlySales.data.filter(sales => sales.storeID === selectedStore.id)
            .filter(sales => sales.yearsPrior === 0);
        const selectedStoreYearlySales = selectedStoreMonthlySales.reduce((total, current) => total + current.totalSales, 0);
        const selectedStoreYearlyCogs = selectedStoreMonthlySales.reduce((total, current) => total + current.totalCostOfGoods, 0);
        const selectedStoreYearlyProfit = safeProfitMargin(selectedStoreYearlySales, selectedStoreYearlyCogs);

        const comparatorStoresIDs = selectedComparator?.getStores().map(store => store.id);

        const selectedComparatorsMonthlySales = monthlySales.data.filter(sales => comparatorStoresIDs?.includes(sales.storeID));
        const selectedComparatorsYearlyData = _(selectedComparatorsMonthlySales).filter(sales => sales.yearsPrior === 0)
            .groupBy(selectedComparatorsMonthlySales => selectedComparatorsMonthlySales.storeID)
            .map((group, key) => {
                const storeId = key;
                const storeName = selectedComparator?.getStores().find(x => x.id === storeId)?.name ?? "";
                const sales = group.reduce((total, current) => total + current.totalSales, 0);
                const cogs = group.reduce((total, current) => total + current.totalCostOfGoods, 0);
                return {
                    storeId,
                    storeName,
                    profit: safeProfitMargin(sales, cogs)
                };

            })
            .value();

        interface StoreIdAndProfit {
            storeId: string,
            storeName: string,
            profit: number
        }

        let storesProfit = [] as StoreIdAndProfit[];
        storesProfit.push({
            storeId: selectedStore.id,
            storeName: selectedStore.name,
            profit: selectedStoreYearlyProfit
        });
        selectedComparatorsYearlyData.forEach(x => storesProfit.push(x));

        const sortedProfitData = storesProfit.sort((a, b) => {
            return b.profit - a.profit;
        });

        const rankedStores = [] as RankedStoreByProfit[];

        for (let i in sortedProfitData) {
            const rankValue = 1 + sortedProfitData.findIndex(item => {
                if (item.profit === sortedProfitData[i].profit) {
                    return true;
                } else {
                    return false;
                }
            });

            let values = {} as RankedStoreByProfit;
            values = {
                rank: rankValue,
                isSelected: sortedProfitData[i].storeId === selectedStore.id,
                storeName: sortedProfitData[i].storeName,
                value: sortedProfitData[i].profit
            };

            rankedStores.push(values);
        }

        rankedGrossProfit.data.rankedStores = rankedStores;
        return rankedGrossProfit;
    }
);

export const selectRollingGrossProfit = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectRankedGrossProfit,
    (selectedStore, selectedComparator, rankedGrossProfit) => {
        const id = "rolling-gross-profit";
        let label = "Rolling gross profit";

        const selectedStoreRank = rankedGrossProfit.data.rankedStores.find(x => x.isSelected)?.rank;
        const rank = selectedStoreRank === undefined ? 0 : selectedStoreRank;
        const denominator = rankedGrossProfit.data.rankedStores.length;
        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;
        const selectedPercentile = rank / denominator;

        let ragStatus;
        let ragValue;

        if (selectedPercentile < topThirdPercentile) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = `${selectedStore?.name} is a top performer for gross profit relative to ${selectedComparator?.name}`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            ragStatus = RagIndicatorStatus.Red;
            ragValue = `${selectedStore?.name} is underperforming for gross profit relative to ${selectedComparator?.name}`;
        } else {
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = `${selectedStore?.name} is average for gross profit relative to ${selectedComparator?.name}`;
        }

        return new RagIndicator(id, ragStatus, label, ragValue, rankedGrossProfit.isLoading, rankedGrossProfit.hasErrors);
    }
);

// Chapter 3.3
export const selectYearlyNetProfit = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectMonthlySales(state),
    (state: RootState) => selectCostReferenceDate(state),
    (state: RootState) => selectYearlyCosts(state),
    (selectedStore, selectedComparator, monthlySales, costReferenceDate, yearlyCosts) => {
        interface StoreYearlyData {
            revenue: number,
            cogs: number,
            staffCosts: number,
            propertyCosts: number,
            netProfit: number
        }

        interface YearlyGrossProfit {
            selectedStoreYearlyData: StoreYearlyData,
            selectedComparatorYearlyData: StoreYearlyData
        }

        const yearlyNetProfit: DataWrapper<YearlyGrossProfit> = {
            isLoading: monthlySales.isLoading,
            hasErrors: monthlySales.hasErrors,
            data: {
                selectedStoreYearlyData: {} as StoreYearlyData, selectedComparatorYearlyData: {} as StoreYearlyData
            }
        };
        if (!selectedStore || yearlyNetProfit.hasErrors || yearlyNetProfit.isLoading) {
            return yearlyNetProfit;
        }

        const referenceDate = costReferenceDate.endOf("day");
        const priorTwelveMonthsOfReferenceDate = referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf("day");

        // Store data
        const selectedStoreMonthlySales = monthlySales.data.filter(sales => sales.storeID === selectedStore.id);
        const selectedStoreYearlyCosts = yearlyCosts.data.filter(costs => costs.storeID === selectedStore.id);

        const revenue = selectedStoreMonthlySales.filter(sales => DateTime.fromISO(sales.monthStartDate.toLocaleString()) >= priorTwelveMonthsOfReferenceDate)
            .filter(sales => DateTime.fromISO(sales.monthStartDate.toLocaleString()) <= referenceDate)
            .reduce((total, current) => total + current.totalSales, 0);
        const cogs = selectedStoreMonthlySales.filter(costs => DateTime.fromISO(costs.monthStartDate.toLocaleString()) >= priorTwelveMonthsOfReferenceDate)
            .filter(costs => DateTime.fromISO(costs.monthStartDate.toLocaleString()) <= referenceDate)
            .reduce((total, current) => total + current.totalCostOfGoods, 0);
        const staffCosts = selectedStoreYearlyCosts.reduce((total, current) => total + current.payrollCosts, 0);
        const propertyCosts = selectedStoreYearlyCosts.reduce((total, current) => total + current.propertyCosts, 0);
        const otherCosts = selectedStoreYearlyCosts.reduce((total, current) => total + current.otherCosts, 0);
        const netProfit = revenue - cogs - staffCosts - propertyCosts - otherCosts;

        yearlyNetProfit.data.selectedStoreYearlyData = {
            revenue: revenue,
            cogs: cogs,
            staffCosts: staffCosts,
            propertyCosts: propertyCosts,
            netProfit: netProfit
        };

        // Comparator data
        const comparatorStoreIDs = selectedComparator?.getStores().map(store => store.id) ?? [];
        const selectedComparatorMonthlySales = monthlySales.data.filter(sales => comparatorStoreIDs.includes(sales.storeID));
        const selectedComparatorYearlyCosts = yearlyCosts.data.filter(costs => comparatorStoreIDs.includes(costs.storeID));

        const selectedComparatorYearlyRevenueAndCogs = _(selectedComparatorMonthlySales).filter(sales => DateTime.fromISO(sales.monthStartDate.toLocaleString()) >= priorTwelveMonthsOfReferenceDate)
            .filter(sales => DateTime.fromISO(sales.monthStartDate.toLocaleString()) <= referenceDate)
            .groupBy(comparator => comparator.storeID)
            .map((group, key) => {
                const revenue = group.reduce((total, current) => total + current.totalSales, 0);
                const cogs = group.reduce((total, current) => total + current.totalCostOfGoods, 0);
                return {
                    revenue,
                    cogs
                };
            })
            .value();

        const selectedComparatorYearlyAllCosts = _(selectedComparatorYearlyCosts)
            .groupBy(comparator => comparator.storeID)
            .map((group, key) => {
                const staffCosts = group.reduce((total, current) => total + current.payrollCosts, 0);
                const propertyCosts = group.reduce((total, current) => total + current.propertyCosts, 0);
                const otherCosts = group.reduce((total, current) => total + current.otherCosts, 0);
                return {
                    staffCosts,
                    propertyCosts,
                    otherCosts
                };
            })
            .value();

        let comparatorRevenue, comparatorCOGs, comparatorStaffCosts, comparatorPropertyCosts, comparatorOtherCosts;

        if (selectedComparatorYearlyRevenueAndCogs.length !== 0) {
            comparatorRevenue = median(selectedComparatorYearlyRevenueAndCogs.map(comparator => comparator.revenue));
            comparatorCOGs = median(selectedComparatorYearlyRevenueAndCogs.map(comparator => comparator.cogs));
        }
        if (selectedComparatorYearlyAllCosts.length > 0) {
            comparatorStaffCosts = median(selectedComparatorYearlyAllCosts.map(comparator => comparator.staffCosts));
            comparatorPropertyCosts = median(selectedComparatorYearlyAllCosts.map(comparator => comparator.propertyCosts));
            comparatorOtherCosts = median(selectedComparatorYearlyAllCosts.map(comparator => comparator.otherCosts));
        }
        const comparatorNetProfit = comparatorRevenue - comparatorCOGs - comparatorStaffCosts - comparatorPropertyCosts - comparatorOtherCosts;

        yearlyNetProfit.data.selectedComparatorYearlyData = {
            revenue: comparatorRevenue,
            cogs: comparatorCOGs,
            staffCosts: comparatorStaffCosts,
            propertyCosts: comparatorPropertyCosts,
            netProfit: comparatorNetProfit
        };

        return yearlyNetProfit;
    }
);

export const selectProfitabilityEvaluation = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectYearlyNetProfit,
    (store, comparator, profitData) => {
        const id = "profitability-evaluation";
        let label = "Profitability evaluation";

        const storeData = profitData.data.selectedStoreYearlyData;
        const comparatorData = profitData.data.selectedComparatorYearlyData;

        const netProfitStore = (storeData.revenue === 0) ? 0 : 100 * storeData.netProfit / storeData.revenue;
        const netProfitComparator = (comparatorData.revenue === 0) ? 0 : 100 * comparatorData.netProfit / comparatorData.revenue;
        const netProfitRelative = (netProfitComparator === 0) ? 0 : ((netProfitStore - netProfitComparator) / Math.abs(netProfitComparator)) * 100;

        let ragStatus;
        let ragValue;

        if ((storeData.propertyCosts === 0 || storeData.staffCosts === 0) && netProfitComparator === 0) {
            ragStatus = RagIndicatorStatus.NoData;
            ragValue = `This indicator isn't available because it requires your company's property and payroll cost data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Cost dataset and refresh your company's Analytics.`;
        } else if (netProfitRelative > 50) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = `The net profit (%) for ${store?.name} is high relative to ${comparator?.name}`;
        } else if (netProfitRelative < -50) {
            ragStatus = RagIndicatorStatus.Red;
            ragValue = `The net profit (%) for ${store?.name} is low relative to ${comparator?.name}`;
        } else { // equal to or between the 2 options
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = `The net profit (%) for ${store?.name} is broadly in line with ${comparator?.name}`;
        }

        return new RagIndicator(id, ragStatus, label, ragValue, profitData.isLoading, profitData.hasErrors);
    }
);

export default profitSlice;
