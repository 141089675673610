import { ResultSet } from "@cubejs-client/core";
import { immerable } from "immer";

import { AppThunk } from "appThunk";
import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";

export class SpendCategory {
    [immerable] = true;

    public readonly name: string;
    private _isSelected: boolean;

    constructor(
        name: string,
        isSelected: boolean
    ) {
        this.name = name;
        this._isSelected = isSelected;
    }

    isSelected() {
        return this._isSelected;
    }

    setIsSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    toggleIsSelected() {
        this._isSelected = !this._isSelected;
    }
}

export const loadSpendCategories = (): AppThunk<Promise<SpendCategory[]>> => async (dispatch) => {
    try {
        const queryClientSpendCategories = {
            dimensions: ["D_ProductCategory.ProductCategory3"],
            filters: [{
                member: "D_ProductCategory.PK_ProductCategory",
                operator: "notEquals",
                values: ["-1"]
            }, {
                member: "D_Product.CurrentRecord",
                operator: "equals",
                values: ["Y"]
            }]
        };
        const resultSetClientCategories = await dispatch(cubeLoad(queryClientSpendCategories)) as unknown as ResultSet;
        const rawData = resultSetClientCategories.rawData();//.map(item => item["D_ProductCategory.ProductCategory3"]);
        return rawData.map(row => {
            const name = row["D_ProductCategory.ProductCategory3"] as string;
            return new SpendCategory(name, true);
        });

    } catch (error) {
        dispatch(logError("Error loading SpendCategories.", error));
        throw error;
    }
};
