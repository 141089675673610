import React from "react";

import { Box } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import Waterfall from "components/visuals/Waterfall";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectYearlyNetProfit } from "modules/customer/insights/portfolioNew/profit/profitSlice";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";
import mathUtils from "utils/mathUtils";

const NetProfitChart: React.FC = () => {
    const colourPalette = useColourPalette();
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);
    const yearlyNetProfit = useAppSelector(selectYearlyNetProfit);
    
    const upDownColour = (value: number) => {
        return (value < 0) ? colourPalette.waterfall.negative : colourPalette.waterfall.positive;
    };

    const upDownNeutralColour = (value: number) => {
        if (value !== 0) {
            return upDownColour(value);
        } else {
            return "";
        }
    };

    const waterfallData = [
        {
            name: "Total revenue",
            y: yearlyNetProfit.data.selectedStoreYearlyData.revenue,
            color: colourPalette.waterfall.neutral,
            custom: {
                comparatorValue: yearlyNetProfit.data.selectedComparatorYearlyData.revenue,
                isCost: false
            }
        },
        {
            name: "COGS",
            y: -yearlyNetProfit.data.selectedStoreYearlyData.cogs,
            color: upDownColour(-yearlyNetProfit.data.selectedStoreYearlyData.cogs),
            custom: {
                comparatorValue: -yearlyNetProfit.data.selectedComparatorYearlyData.cogs,
                isCost: true
            }
        },
        {
            name: "Staff costs",
            y: -yearlyNetProfit.data.selectedStoreYearlyData.staffCosts,
            color: upDownColour(-yearlyNetProfit.data.selectedStoreYearlyData.staffCosts),
            custom: {
                comparatorValue: -yearlyNetProfit.data.selectedComparatorYearlyData.staffCosts,
                isCost: true
            }
        },
        {
            name: "Property costs",
            y: -yearlyNetProfit.data.selectedStoreYearlyData.propertyCosts,
            color: upDownColour(-yearlyNetProfit.data.selectedStoreYearlyData.propertyCosts),
            custom: {
                comparatorValue: -yearlyNetProfit.data.selectedComparatorYearlyData.propertyCosts,
                isCost: true
            }
        },
        {
            name: "Net profit",
            y: yearlyNetProfit.data.selectedStoreYearlyData.netProfit,
            color: colourPalette.waterfall.neutral,
            isSum: true,
            custom: {
                comparatorValue: yearlyNetProfit.data.selectedComparatorYearlyData.netProfit,
                isCost: false
            }
        }
    ];

    const waterfallDataFiltered = waterfallData.filter(item => item.y !== 0);

    const options = {
        xAxis: {
            type: "category"
        },
        tooltip: {
            headerFormat: "<table>",
            pointFormatter: function (): any {
                const point = this;
                // @ts-ignore
                const categoryName = point.name;
                // @ts-ignore
                const selectedStoreValue = point.y;
                // @ts-ignore
                const comparatorValue = point.custom.comparatorValue;
                const variance = mathUtils.safePercentageChange(selectedStoreValue, comparatorValue);
                // @ts-ignore
                const swapColourSign = (point.custom.isCost ? (-1) : 1);
                const varianceColour = upDownNeutralColour(swapColourSign * variance);
                const categoryFormattingArr = ["color:" + colourPalette.comparators[0], "color:" + colourPalette.comparators[1]];
                const valueFormattingArr = ["", "", "color:" + varianceColour];

                const tooltip = stringUtils.tooltipHTML(
                    [store?.name, comparator?.name, "Variance"],
                    {
                        values: [numberFormatter.toGBP(selectedStoreValue), numberFormatter.toGBP(comparatorValue), numberFormatter.toPercentage(variance)],
                        header: categoryName,
                        categoryFormattingArr,
                        valueFormattingArr
                    }
                );
                let percentageTooltip = "";
                if (categoryName !== "Total revenue") {
                    const selectedStorePercentage = mathUtils.safePercentage(swapColourSign * selectedStoreValue, yearlyNetProfit.data.selectedStoreYearlyData.revenue);
                    const comparatorPercentage = mathUtils.safePercentage(swapColourSign * comparatorValue, yearlyNetProfit.data.selectedComparatorYearlyData.revenue);
                    const percentageVariance = selectedStorePercentage - comparatorPercentage;
                    const percentageVarianceColour = upDownNeutralColour(swapColourSign * percentageVariance);
                    const valueFormattingArr = ["", "", "color:" + percentageVarianceColour];
                    const header = categoryName + " as (%) of revenue";
                    percentageTooltip = stringUtils.tooltipHTML([store?.name, comparator?.name, "Variance"],{
                        values: [
                            numberFormatter.toPercentage(selectedStorePercentage),
                            numberFormatter.toPercentage(comparatorPercentage),
                            numberFormatter.toPercentage(percentageVariance)
                        ],
                        header,
                        categoryFormattingArr,
                        valueFormattingArr
                    });
                }
                return tooltip.concat(percentageTooltip);
            },
            footerFormat: "</table>",
            useHTML: true
        },
        series: [{
            name: "Sales to profit",
            data: waterfallDataFiltered
        }]
    };

    return (
        <Box data-cy="sales-to-profit">
            <Waterfall loading={yearlyNetProfit.isLoading} error={yearlyNetProfit.hasErrors} options={options} />
        </Box>
    );
};

export default NetProfitChart;
