import React from "react";

import { Box, Button, Card, CardActions, CardContent, Typography } from "@material-ui/core";
import SaveAltRoundedIcon from "@material-ui/icons/SaveAltRounded";
import { withStyles } from "@material-ui/core/styles";

import Callback from "./Callback";

import { useAppDispatch, useAppSelector } from "store";
import {
    downloadPlanDetails,
    downloadTermsOfUse,
    getSubscription,
    selectSubscription
} from "modules/customer/account/billing/billingSlice";
import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        flexGrow: 1
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        "&:last-child": {
            padding: theme.spacing(0)
        }
    }
}))(CardContent);

const StyledCardActions = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(2),
        alignItems: "flex-end",
        justifyContent: "space-between"
    }
}))(CardActions);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const CurrentPlan: React.FC = () => {
    const dispatch = useAppDispatch();
    const subscription = useAppSelector(selectSubscription);

    const handleDownloadPlanDetailsClick = () => {
        dispatch(downloadPlanDetails());
    };

    const handleDownloadTermsOfUseClick = () => {
        dispatch(downloadTermsOfUse());
    };

    React.useEffect(() => {
        dispatch(getSubscription());
    }, [dispatch]);

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Box color="text.disabled">
                <Typography variant="subtitle1" component="div" gutterBottom>
                    Current plan
                </Typography>
            </Box>
            <StyledCard elevation={0}>
                <StyledCardContent>
                    <Box color="text.disabled">
                        <Typography variant="h3" component="div">
                            {subscription.planName}
                        </Typography>
                    </Box>
                    <br />
                    <Typography variant="subtitle1" component="div">
                        <b>{numberFormatter.toGBP(subscription.priceInPence / 100, 2, false)}</b> per month
                        <br />
                        <b>{subscription.numberOfUsers}</b> user licenses
                        <br />
                        <b>{subscription.dataRefreshFrequency}</b> data refresh
                    </Typography>
                    <br />
                    <Typography variant="body1" component="div" color="textSecondary" gutterBottom>
                        Your plan renews on {subscription.renewalDate.toLocaleString()}
                    </Typography>
                    <StyledButton
                        variant="text"
                        size="medium"
                        color="default"
                        disableElevation
                        startIcon={<SaveAltRoundedIcon />}
                        onClick={handleDownloadPlanDetailsClick}
                    >
                        Download Plan Details
                    </StyledButton>
                    <br />
                    <StyledButton
                        variant="text"
                        size="medium"
                        color="default"
                        disableElevation
                        startIcon={<SaveAltRoundedIcon />}
                        onClick={handleDownloadTermsOfUseClick}
                    >
                        Download Terms of Use
                    </StyledButton>
                    <br />
                    <br />
                </StyledCardContent>
                <StyledCardActions disableSpacing>
                    <Box data-cy="pages-customer-account-billing-callback">
                        <Typography variant="subtitle1" component="div">
                            Questions about your plan?
                        </Typography>
                        <br />
                        <Typography variant="body1" component="div" color="textSecondary">
                            Callback available Monday - Friday
                            <br />
                            09:00 - 17:00 GMT
                            <br />
                            We aim to respond within 15 minutes
                        </Typography>
                    </Box>
                    <Callback />
                </StyledCardActions>
            </StyledCard>
        </Box>
    );
};

export default CurrentPlan;
