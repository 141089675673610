import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface UserInfo {
    id: string,
    firstName: string,
    lastName: string,
    accountId: string,
    companyName: string,
    companyDisplayName: string,
    email: string,
    isAppAdmin: boolean,
    isAccountOwner: boolean,
    isAccountAdmin: boolean,
    isAccountEditor: boolean,
    isAccountViewer: boolean,
    acceptedTermsOfUse: boolean,
    isAccountSetupComplete: boolean,
    isDataProvided: boolean
}

interface IdleVisibility {
    isVisible: boolean
}

interface LoggedOutVisibility {
    isVisible: boolean
}

interface AuthState {
    isAuthenticationChecked: boolean,
    isAuthenticated: boolean,
    userInfo: UserInfo,
    latestActivityTime: number,
    idleVisibility: IdleVisibility,
    loggedOutVisibility: LoggedOutVisibility
}

const initialState: AuthState = {
    isAuthenticationChecked: false,
    isAuthenticated: false,
    userInfo: {
        id: "",
        firstName: "",
        lastName: "",
        accountId: "",
        companyName: "",
        companyDisplayName: "",
        email: "",
        isAppAdmin: false,
        isAccountOwner: false,
        isAccountAdmin: false,
        isAccountEditor: false,
        isAccountViewer: false,
        acceptedTermsOfUse: false,
        isAccountSetupComplete: false,
        isDataProvided: false
    },
    latestActivityTime: 0,
    idleVisibility: {
        isVisible: false
    },
    loggedOutVisibility: {
        isVisible: false
    }
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        updateLatestActivityTime: (state) => {
            state.latestActivityTime = Date.now();
        },
        showIdle: (state) => {
            state.idleVisibility.isVisible = true;
        },
        hideIdle: (state) => {
            state.idleVisibility = initialState.idleVisibility;
        },
        showLoggedOut: (state) => {
            state.loggedOutVisibility.isVisible = true;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(getUserInfo.rejected, (state: AuthState) => {
            state.isAuthenticationChecked = true;
            state.isAuthenticated = false;
        });
        builder.addCase(getUserInfo.fulfilled, (state: AuthState, action: PayloadAction<UserInfo>) => {
            state.isAuthenticationChecked = true;
            state.isAuthenticated = true;
            state.userInfo = action.payload;
        });
    }
});

export const { updateLatestActivityTime, showIdle, hideIdle, showLoggedOut } = authSlice.actions;

export const getUserInfo = createAppAsyncThunk(
    "auth/getUserInfo",
    async (arg, thunkAPI) => {
        const response = await thunkAPI.dispatch(apiGet("/auth/user-info"));
        if (response.status === ApiResponseStatus.Ok) {
            return response.data.userInfo;
        }
        if (response.status === ApiResponseStatus.Unauthorized) {
            //no message, it's just checking authentication
            return thunkAPI.rejectWithValue(null);
        }

        if (response.status === ApiResponseStatus.NotFound) {
            thunkAPI.dispatch(notifyError("User not found."));
        } else {
            thunkAPI.dispatch(notifyError("Error loading user info."));
        }
        return thunkAPI.rejectWithValue(null);
    }
);

export const login = (): AppThunk => () => {
    window.location.href = "/auth/login";
};

export const logout = (): AppThunk => () => {
    window.location.href = "/auth/logout";
};

export const selectIsAuthenticationChecked = (state: RootState): boolean => {
    return state.auth.isAuthenticationChecked;
};

export const selectIsAuthenticated = (state: RootState): boolean => {
    return state.auth.isAuthenticated;
};

export const selectUserInfo = (state: RootState): UserInfo => {
    return state.auth.userInfo;
};

export const selectLatestActivityTime = (state: RootState) => {
    return state.auth.latestActivityTime;
};

export const selectLoggedOutVisibility = (state: RootState): LoggedOutVisibility => {
    return state.auth.loggedOutVisibility;
};

export const selectIdleVisibility = (state: RootState) => {
    return state.auth.idleVisibility;
};

export const selectIsSupportSession = createSelector(
    selectUserInfo,
    (userInfo) => {
        return userInfo.isAppAdmin && userInfo.accountId !== "00000000-0000-0000-0000-000000000000";
    }
);

export default authSlice;
