import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import {
    selectCatchmentAccountIds,
    selectPinnedLocation,
    selectStores,
    selectTargetStoreCategory
} from "modules/customer/tools/location/locationSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";
import { numberSortExpression, SortDirection, stringSortExpression } from "utils/sortUtils";

import { CatchmentArea, loadCatchmentAreas } from "./catchmentAreas";
import { ExistingStore } from "./existingStore";
import { PotentiallyCannibalisedStore, loadPotentiallyCannibalisedStores } from "./potentiallyCannibalisedStores";
import { RetailCentre } from "modules/customer/tools/location/retailCentre";

export interface CannibalisationPerOutputArea {
    code: string,
    type: string,
    levelOfCannibalisation: number,
    uniqueGainedCustomers: number,
    sharedCustomers: number,
    likelihoodOfVisiting: number,
    totalPopulation: number,
    customerProfile: string,
    catchmentIDs: string[]
}

interface LoadCannibalisationResponse {
    potentiallyCannibalisedStores: PotentiallyCannibalisedStore[],
    proposedStoreCatchmentArea: CatchmentArea,
    existingStoresCatchmentAreas: CatchmentArea[]
    existingStores: ExistingStore[]
}

export enum SharedCustomersByCustomerProfileSortField {
    CustomerProfile,
    SharedCustomers,
    UniqueGainedPopulation
}

interface SharedCustomersByCustomerProfileSort {
    field: SharedCustomersByCustomerProfileSortField,
    direction: SortDirection
}

export enum SharedPopulationByStoreSortField {
    Store,
    StraightLineDistance,
    SharedCustomers
}

interface SharedPopulationByStoreSort {
    field: SharedPopulationByStoreSortField,
    direction: SortDirection
}

interface CannibalisationState {
    isLoading: boolean,
    hasErrors: boolean,
    potentiallyCannibalisedStores: PotentiallyCannibalisedStore[],
    proposedStoreCatchmentArea: CatchmentArea | undefined,
    existingStoresCatchmentAreas: CatchmentArea[],
    existingStores: ExistingStore[],
    sharedPopulationByStoreSort: SharedPopulationByStoreSort,
    sharedCustomersByCustomerProfileSort: SharedCustomersByCustomerProfileSort,
    likelihoodOfVisitingThresholds: number[]
}

const initialState: CannibalisationState = {
    isLoading: false,
    hasErrors: false,
    potentiallyCannibalisedStores: [],
    proposedStoreCatchmentArea: undefined,
    existingStoresCatchmentAreas: [],
    existingStores: [],
    sharedPopulationByStoreSort: {
        field: SharedPopulationByStoreSortField.SharedCustomers,
        direction: SortDirection.DESC
    },
    sharedCustomersByCustomerProfileSort: {
        field: SharedCustomersByCustomerProfileSortField.SharedCustomers,
        direction: SortDirection.DESC
    },
    likelihoodOfVisitingThresholds: [0, 100]
};

const cannibalisationSlice = createSlice({
    name: "customer/tools/location/cannibalisation",
    initialState,
    reducers: {
        clearPotentiallyCannibalisedStores: (state) => {
            state.potentiallyCannibalisedStores = initialState.potentiallyCannibalisedStores;
        },
        clearExistingStoresCatchmentAreas: (state) => {
            state.existingStoresCatchmentAreas = initialState.existingStoresCatchmentAreas;
        },
        clearProposedStoreCatchmentArea: (state) => {
            state.proposedStoreCatchmentArea = initialState.proposedStoreCatchmentArea;
        },
        clearExistingStores: (state) => {
            state.existingStores = initialState.existingStores;
        },
        toggleExistingStoreIsSelected: (state, action: PayloadAction<string>) => {
            const storeName = action.payload;
            state.existingStores.find(store => store.name === storeName)?.toggleIsSelected();
        },
        chooseAllExistingStores: (state) => {
            state.existingStores.forEach(store => store.setIsSelected(true));
        },
        deselectAllExistingStores: (state) => {
            state.existingStores.forEach(store => store.setIsSelected(false));
        },
        setSharedCustomersByCustomerProfileSort: (state, action: PayloadAction<SharedCustomersByCustomerProfileSort>) => {
            state.sharedCustomersByCustomerProfileSort = action.payload;
        },
        clearSharedCustomersByCustomerProfileSort: (state) => {
            state.sharedCustomersByCustomerProfileSort = initialState.sharedCustomersByCustomerProfileSort;
        },
        setSharedPopulationByStoreSort: (state, action: PayloadAction<SharedPopulationByStoreSort>) => {
            state.sharedPopulationByStoreSort = action.payload;
        },
        clearSharedPopulationByStoreSort: (state) => {
            state.sharedPopulationByStoreSort = initialState.sharedPopulationByStoreSort;
        },
        setLikelihoodOfVisitingThresholds: (state, action: PayloadAction<number[]>) => {
            state.likelihoodOfVisitingThresholds = action.payload;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCannibalisation.pending, (state: CannibalisationState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadCannibalisation.rejected, (state: CannibalisationState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.potentiallyCannibalisedStores = initialState.potentiallyCannibalisedStores;
            state.proposedStoreCatchmentArea = initialState.proposedStoreCatchmentArea;
            state.existingStoresCatchmentAreas = initialState.existingStoresCatchmentAreas;
            state.existingStores = initialState.existingStores;
        });
        builder.addCase(loadCannibalisation.fulfilled, (state: CannibalisationState, action: PayloadAction<LoadCannibalisationResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.potentiallyCannibalisedStores = action.payload.potentiallyCannibalisedStores;
            state.proposedStoreCatchmentArea = action.payload.proposedStoreCatchmentArea;
            state.existingStoresCatchmentAreas = action.payload.existingStoresCatchmentAreas;
            state.existingStores = action.payload.existingStores;
        });
    }
});

export const {
    toggleExistingStoreIsSelected,
    chooseAllExistingStores,
    deselectAllExistingStores,
    setSharedCustomersByCustomerProfileSort,
    setSharedPopulationByStoreSort,
    setLikelihoodOfVisitingThresholds
} = cannibalisationSlice.actions;

export const loadCannibalisation = createAppAsyncThunk(
    "customer/tools/location/cannibalisation/loadCannibalisation",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const pinnedLocation = selectPinnedLocation(state);
            const targetStoreCategoryId = selectTargetStoreCategory(state)?.id;
            const clientStores = selectStores(state);
            const catchmentAccountIds = selectCatchmentAccountIds(state);

            const proposedStoreCatchmentAreaPromise = pinnedLocation ? thunkAPI.dispatch(loadCatchmentAreas([pinnedLocation?.retailCentre], true, catchmentAccountIds.scenario)) : [];
            const potentiallyCannibalisedStores = await thunkAPI.dispatch(loadPotentiallyCannibalisedStores(pinnedLocation, targetStoreCategoryId, clientStores));

            // Temporarily filtering to closest 5 client stores
            const filteredStores = potentiallyCannibalisedStores.sort((storeA, storeB) => numberSortExpression(storeA.distanceToProposedStore, storeB.distanceToProposedStore, SortDirection.ASC)).slice(0, 5);

            const existingStoresRetailCentres = filteredStores.map(store => store.retailCentre);
            const storeCatchments = await thunkAPI.dispatch(loadCatchmentAreas(existingStoresRetailCentres, false, catchmentAccountIds.baseline));
            const proposedStoreCatchmentArea = await proposedStoreCatchmentAreaPromise;

            const existingStores = filteredStores.map(store => new ExistingStore(store.name, store.storeCategoryId, store.retailCentre.id, true));
            return {
                potentiallyCannibalisedStores: filteredStores,
                proposedStoreCatchmentArea: proposedStoreCatchmentArea[0],
                existingStoresCatchmentAreas: storeCatchments,
                existingStores
            };
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Cannibalisation.", error));
            return thunkAPI.rejectWithValue([]);
        }
    }
);

export const clearCannibalisation = (): AppThunk => (dispatch) => {
    dispatch(cannibalisationSlice.actions.clearExistingStoresCatchmentAreas());
    dispatch(cannibalisationSlice.actions.clearPotentiallyCannibalisedStores());
    dispatch(cannibalisationSlice.actions.clearProposedStoreCatchmentArea());
    dispatch(cannibalisationSlice.actions.clearExistingStores());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.location.cannibalisation.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.location.cannibalisation.hasErrors;
};

export const selectPotentiallyCannibalisedStores = (state: RootState) => {
    return state.customer.tools.location.cannibalisation.potentiallyCannibalisedStores;
};

export const selectProposedStoreCatchmentArea = (state: RootState) => {
    return state.customer.tools.location.cannibalisation.proposedStoreCatchmentArea;
};

export const selectExistingStoresCatchmentAreas = (state: RootState) => {
    return state.customer.tools.location.cannibalisation.existingStoresCatchmentAreas;
};

export const selectExistingStores = (state: RootState) => {
    return state.customer.tools.location.cannibalisation.existingStores;
};

export const selectSharedCustomersByCustomerProfileSort = (state: RootState) => {
    return state.customer.tools.location.cannibalisation.sharedCustomersByCustomerProfileSort;
};

export const selectSharedPopulationByStoreSort = (state: RootState) => {
    return state.customer.tools.location.cannibalisation.sharedPopulationByStoreSort;
};

export const selectLikelihoodOfVisitingThresholds = (state: RootState) => {
    return state.customer.tools.location.cannibalisation.likelihoodOfVisitingThresholds;
};

export const selectCannibalisationFilterHasChanged = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectExistingStores,
    (isLoading, hasErrors, existingStores) => {
        if (isLoading || hasErrors) {
            return false;
        }
        return existingStores.some(store => !store.isSelected());
    }
);

export const selectSelectedCannibalisedStores = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectExistingStores,
    selectPotentiallyCannibalisedStores,
    (isLoading, hasErrors, existingStores, cannibalisedStores) => {
        if (isLoading || hasErrors) {
            return [];
        }
        const selectedStoreNames = existingStores.filter(store => store.isSelected()).map(store => store.name);
        return cannibalisedStores.filter(store => selectedStoreNames.includes(store.name));
    }
);

export const selectLevelOfCannibalisation = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectProposedStoreCatchmentArea,
    selectExistingStoresCatchmentAreas,
    selectExistingStores,
    selectLikelihoodOfVisitingThresholds,
    (isLoading, hasErrors, proposedStoreCatchment, existingStoresCatchments, existingStores, percentageThresholds) => {
        if (isLoading || hasErrors || !proposedStoreCatchment) {
            return [] as CannibalisationPerOutputArea[];
        }

        const selectedStores = existingStores.filter(store => store.isSelected());

        const allCatchmentOutputAreas: {
            catchmentID: string,
            code: string,
            totalPopulation: number,
            likelihoodOfVisiting: number,
            customerProfile: string
        }[] = [];

        for (const catchment of existingStoresCatchments) {
            if (selectedStores.find(store => store.retailCentreId === catchment.retailCentre.id
                && store.storeCategoryId === catchment.retailCentre.storeCategoryId)) {
                const catchmentID = createCatchmentID(catchment.retailCentre, false);
                const outputAreas = catchment.outputAreas
                    .map(oa => ({
                        catchmentID,
                        code: oa.code,
                        likelihoodOfVisiting: oa.likelihoodOfVisting,
                        totalPopulation: oa.totalPopulation,
                        customerProfile: oa.customerProfile
                    }))
                    .filter(oa => (oa.likelihoodOfVisiting > (percentageThresholds[0] / 100))
                        && (oa.likelihoodOfVisiting <= (percentageThresholds[1] / 100)));
                allCatchmentOutputAreas.push(...outputAreas);
            }
        }
        const proposedStoreCatchmentID = createCatchmentID(proposedStoreCatchment.retailCentre, true);
        const proposedStoreCatchmentOutputAreas = proposedStoreCatchment.outputAreas
            .map(oa => ({
                catchmentID: proposedStoreCatchmentID,
                code: oa.code,
                likelihoodOfVisiting: oa.likelihoodOfVisting,
                totalPopulation: oa.totalPopulation,
                customerProfile: oa.customerProfile
            }))
            .filter(oa => (oa.likelihoodOfVisiting > (percentageThresholds[0] / 100))
                && (oa.likelihoodOfVisiting <= (percentageThresholds[1] / 100)));
        allCatchmentOutputAreas.push(...proposedStoreCatchmentOutputAreas);

        const outputAreas: CannibalisationPerOutputArea[] = _(allCatchmentOutputAreas)
            .groupBy(oa => oa.code)
            .map((group, oaCode) => {
                const groupSize = _.size(group);
                const proposedOa = group.find(oa => oa.catchmentID === proposedStoreCatchmentID);
                let type = "Existing", levelOfCannibalisation = 0, uniqueGainedCustomers = 0,
                    likelihoodOfVisiting = 0, sharedCustomers = 0;
                if (proposedOa) {
                    levelOfCannibalisation = groupSize - 1;
                    likelihoodOfVisiting = proposedOa.likelihoodOfVisiting;
                    type = "proposed";
                    if (groupSize > 1) {
                        type = "Cannibalised";
                        sharedCustomers = proposedOa.totalPopulation * proposedOa.likelihoodOfVisiting;
                    } else {
                        uniqueGainedCustomers = proposedOa.totalPopulation * proposedOa.likelihoodOfVisiting;
                    }
                }
                return {
                    code: oaCode,
                    type,
                    levelOfCannibalisation,
                    uniqueGainedCustomers,
                    sharedCustomers,
                    likelihoodOfVisiting,
                    totalPopulation: group[0].totalPopulation,
                    customerProfile: group[0].customerProfile,
                    catchmentIDs: group.map(oa => oa.catchmentID)
                };
            }).value();

        return outputAreas;
    }
);

export const selectSharedPopulation = createSelector(
    selectLevelOfCannibalisation,
    (levelOfCannibalisation) => {
        const sharedPopulation = levelOfCannibalisation
            .reduce((accumulator: number, cannibalisedOa) => {
                accumulator += cannibalisedOa.sharedCustomers;
                return accumulator;
            }, 0);
        return sharedPopulation;
    }
);

export const selectUniquePopulation = createSelector(
    selectLevelOfCannibalisation,
    (levelOfCannibalisation) => {
        const uniquePopulation = levelOfCannibalisation
            .reduce((accumulator: number, cannibalisedOa) => {
                accumulator += cannibalisedOa.uniqueGainedCustomers;
                return accumulator;
            }, 0);
        return uniquePopulation;
    }
);

export const selectSharedPopulationByStore = createSelector(
    selectSelectedCannibalisedStores,
    selectLevelOfCannibalisation,
    selectSharedPopulationByStoreSort,
    (cannibalisedStores, cannibalisation, sort) => {

        const sharedPopulationByStore = [];

        const filteredCannibalisation = cannibalisation.filter(oa => oa.type === "Cannibalised");
        for (const store of cannibalisedStores) {
            const catchmentID = createCatchmentID(store.retailCentre, false);
            const relevantOas = filteredCannibalisation.filter(oa => oa.catchmentIDs.includes(catchmentID));

            const sharedCustomers = relevantOas.reduce((accumulator: number, cannibalisedOa) => {
                accumulator += cannibalisedOa.sharedCustomers;
                return accumulator;
            }, 0);
            sharedPopulationByStore.push({
                store: store.name,
                straightLineDistance: store.distanceToProposedStore,
                sharedCustomers
            });
        }

        sharedPopulationByStore.sort((a, b) => {
            switch (sort.field) {
                case SharedPopulationByStoreSortField.Store:
                    return stringSortExpression(a.store, b.store, sort.direction);
                case SharedPopulationByStoreSortField.SharedCustomers:
                    return numberSortExpression(a.sharedCustomers, b.sharedCustomers, sort.direction);
                case SharedPopulationByStoreSortField.StraightLineDistance:
                    return numberSortExpression(a.straightLineDistance, b.straightLineDistance, sort.direction);
                default:
                    return numberSortExpression(a.sharedCustomers, b.sharedCustomers, SortDirection.DESC);
            }
        });

        return sharedPopulationByStore;
    }
);

export const selectSharedAndNewCustomersByCustomerProfile = createSelector(
    selectLevelOfCannibalisation,
    selectSharedCustomersByCustomerProfileSort,
    (levelOfCannibalisation, sort) => {
        const proposedStoreCatchment = levelOfCannibalisation.filter(oa => oa.type !== "Existing");

        const sharedAndNewCustomersByCustomerProfile = _(proposedStoreCatchment)
            .groupBy(oa => oa.customerProfile)
            .map((group, key) => ({
                customerProfile: key,
                sharedCustomers: group.reduce((accumulator, oa) => accumulator + oa.sharedCustomers, 0),
                uniqueGainedPopulation: group.reduce((accumulator, oa) => accumulator + oa.uniqueGainedCustomers, 0)
            }))
            .sort((a, b) => {
                switch (sort.field) {
                    case SharedCustomersByCustomerProfileSortField.CustomerProfile:
                        return stringSortExpression(a.customerProfile, b.customerProfile, sort.direction);
                    case SharedCustomersByCustomerProfileSortField.SharedCustomers:
                        return numberSortExpression(a.sharedCustomers, b.sharedCustomers, sort.direction);
                    case SharedCustomersByCustomerProfileSortField.UniqueGainedPopulation:
                        return numberSortExpression(a.uniqueGainedPopulation, b.uniqueGainedPopulation, sort.direction);
                    default:
                        return numberSortExpression(a.sharedCustomers, b.sharedCustomers, SortDirection.DESC);
                }
            })
            .value();
        return sharedAndNewCustomersByCustomerProfile;
    }
);

const createCatchmentID = (retailCentre: RetailCentre, isScenario: boolean) => {
    return `${retailCentre.id}_${retailCentre.storeCategoryName}_${isScenario}`;
};

export default cannibalisationSlice;
