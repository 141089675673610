import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { DataWrapper } from "domain/dataWrapper";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";
import { round } from "mathjs";

import { clearAreaHealth, loadAreaHealth } from "./areaHealth/areaHealthSlice";
import { clearCatchment, loadCatchment } from "./catchment/catchmentSlice";
import { clearCompetition, loadCompetition } from "./competition/competitionSlice";
import { clearFootfall, loadFootfall } from "./footfall/footfallSlice";
import { clearRevenue, loadRevenue } from "./revenue/revenueSlice";

import { CatchmentCustomerProfile, loadCatchmentCustomerProfiles } from "./catchmentCustomerProfile";
import { CatchmentSpendTotals, loadCatchmentSpendTotals } from "./catchmentSpendTotals";
import { ClientRegistration, loadClientRegistration } from "./clientRegistration";
import { Comparator } from "./comparator";
import { ComplementaryCategory, loadComplementaryCategories } from "./complementaryCategory";
import { loadCostReferenceDate } from "./costReferenceDate";
import { loadDirectCompetitorNames } from "./directCompetitorNames";
import { KPMGSpendCategory, loadKPMGSpendCategories } from "./kpmgSpendCategory";
import { loadMonthlySales, MonthlySales } from "./monthlySales";
import { loadSalesReferenceDate } from "./salesReferenceDate";
import { loadStores, Store } from "./store";
import { loadWeeklySales, WeeklySales } from "./weeklySales";
import { loadYearlyCosts, YearlyCosts } from "./yearlyCosts";
import { loadOverview } from "./overview/overviewSlice";

interface LoadPortfolioResponse {
    stores: Store[],
    clientRegistration: ClientRegistration,
    salesReferenceDate: DateTime,
    costReferenceDate: DateTime,
    kpmgSpendCategories: KPMGSpendCategory[],
    directCompetitorNames: string[],
    complementaryCategories: ComplementaryCategory[]
}

interface OverviewSubchaptersIds {
    storeSummary: string,
    recommendations: string
}

interface RevenueSubchaptersIds {
    revenue: string,
    rankedRevenueGrowth: string,
    productCategoryMix: string,
    productCategoryGrowth: string
}

interface ProfitSubchaptersIds {
    grossProfitMargin: string,
    rankedGrossProfitMargin: string,
    netProfit: string
}

interface DriversSubchaptersIds {
    storeSize: string,
    staffing: string
}

interface CatchmentSubchaptersIds {
    customerProfiles: string,
    marketCategorySpend: string,
    catchmentAreaDemographics: string
}

interface CompetitionSubchaptersIds {
    nearbyCompetition: string,
    //cannibalisation: string,
    supplyAndDemand: string,
    //marketShare: string
}

interface AreaHealthSubchaptersIds {
    openingsAndClosures: string,
    storeCategoryBreakdown: string
}

interface FootfallSubchaptersIds {
    footfall: string,
    footfallChangesOverTime: string
}

interface SubchaptersIds {
    overview: OverviewSubchaptersIds,
    revenue: RevenueSubchaptersIds,
    profit: ProfitSubchaptersIds,
    drivers: DriversSubchaptersIds,
    catchment: CatchmentSubchaptersIds,
    competition: CompetitionSubchaptersIds,
    areaHealth: AreaHealthSubchaptersIds,
    footfall: FootfallSubchaptersIds
}

interface PortfolioState {
    isLoading: boolean,
    hasErrors: boolean,
    subchaptersIds: SubchaptersIds,
    stores: Store[],
    clientRegistration?: ClientRegistration,
    salesReferenceDate: DateTime,
    costReferenceDate: DateTime,
    kpmgSpendCategories: KPMGSpendCategory[],
    complementaryCategories: ComplementaryCategory[],
    numberOfProductCategories: number,
    store?: Store,
    comparator?: Comparator,
    directCompetitorNames: string[],
    weeklySales: DataWrapper<WeeklySales[]>,
    monthlySales: DataWrapper<MonthlySales[]>,
    yearlyCosts: DataWrapper<YearlyCosts[]>,
    catchmentCustomerProfiles: DataWrapper<CatchmentCustomerProfile[]>
    catchmentSpendTotals: DataWrapper<CatchmentSpendTotals[]>
}

const initialState: PortfolioState = {
    isLoading: false,
    hasErrors: false,
    subchaptersIds: {
        overview: {
            storeSummary: "",
            recommendations: ""
        },
        revenue: {
            revenue: "",
            rankedRevenueGrowth: "",
            productCategoryMix: "",
            productCategoryGrowth: ""
        },
        profit: {
            grossProfitMargin: "",
            rankedGrossProfitMargin: "",
            netProfit: ""
        },
        drivers: {
            storeSize: "",
            staffing: ""
        },
        catchment: {
            customerProfiles: "",
            marketCategorySpend: "",
            catchmentAreaDemographics: "",
        },
        competition: {
            nearbyCompetition: "",
            //cannibalisation: "",
            supplyAndDemand: "",
            //marketShare: ""
        },
        areaHealth: {
            openingsAndClosures: "",
            storeCategoryBreakdown: ""
        },
        footfall: {
            footfall: "",
            footfallChangesOverTime: ""
        }
    },
    stores: [],
    clientRegistration: undefined,
    salesReferenceDate: DateTime.fromMillis(0, { zone: "utc" }),
    costReferenceDate: DateTime.fromMillis(0, { zone: "utc" }),
    kpmgSpendCategories: [],
    complementaryCategories: [],
    numberOfProductCategories: 0,
    store: undefined,
    comparator: undefined,
    directCompetitorNames: [],
    weeklySales: { isLoading: false, hasErrors: false, data: [] },
    monthlySales: { isLoading: false, hasErrors: false, data: [] },
    yearlyCosts: { isLoading: false, hasErrors: false, data: [] },
    catchmentCustomerProfiles: { isLoading: false, hasErrors: false, data: [] },
    catchmentSpendTotals: { isLoading: false, hasErrors: false, data: [] }
};

const portfolioSlice = createSlice({
    name: "customer/insights/portfolioNew",
    initialState,
    reducers: {
        setSubchaptersIds: (state, action: PayloadAction<SubchaptersIds>) => {
            state.subchaptersIds = action.payload;
        },
        clearSubchaptersIds: (state) => {
            state.subchaptersIds = initialState.subchaptersIds;
        },
        clearStores: (state) => {
            state.stores = initialState.stores;
        },
        clearClientRegistration: (state) => {
            state.clientRegistration = initialState.clientRegistration;
        },
        clearSalesReferenceDate: (state) => {
            state.salesReferenceDate = initialState.salesReferenceDate;
        },
        clearCostReferenceDate: (state) => {
            state.costReferenceDate = initialState.costReferenceDate;
        },
        clearKPMGSpendCategories: (state) => {
            state.kpmgSpendCategories = initialState.kpmgSpendCategories;
        },
        clearComplementaryCategories: (state) => {
            state.complementaryCategories = initialState.complementaryCategories;
        },
        clearNumberOfProductCategories: (state) => {
            state.numberOfProductCategories = initialState.numberOfProductCategories;
        },
        chooseStoreAndComparator: (state, action: PayloadAction<{ store: Store, comparator: Comparator }>) => {
            state.store = action.payload.store;
            let comparator = action.payload.comparator;
            const storeLimit = 100;
            const comparatorStores = comparator.getStores();

            if (comparatorStores.length > storeLimit) {
                const sampleStores = [];
                const sortedStores =
                    comparatorStores.sort((a, b) => a.getTotalScore() - b.getTotalScore() || a.id.toLowerCase().localeCompare(b.id.toLowerCase()));
                for (let i = 0; i < 100; i++) {
                    const roundedIndex = round(i * (sortedStores.length/storeLimit));
                    sampleStores.push(sortedStores[roundedIndex]);
                }
                comparator = new Comparator(
                    comparator.type,
                    comparator.name,
                    sampleStores
                );
            }
            state.comparator = comparator;
        },
        clearStoreAndComparator: (state) => {
            state.store = initialState.store;
            state.comparator = initialState.comparator;
        },
        clearDirectCompetitorNames: (state) => {
            state.directCompetitorNames = initialState.directCompetitorNames;
        },
        clearWeeklySales: (state) => {
            state.weeklySales = initialState.weeklySales;
        },
        clearMonthlySales: (state) => {
            state.monthlySales = initialState.monthlySales;
        },
        clearYearlyCosts: (state) => {
            state.yearlyCosts = initialState.yearlyCosts;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadPortfolio.pending, (state: PortfolioState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.stores = initialState.stores;
            state.clientRegistration = initialState.clientRegistration;
            state.salesReferenceDate = initialState.salesReferenceDate;
            state.costReferenceDate = initialState.costReferenceDate;
            state.kpmgSpendCategories = initialState.kpmgSpendCategories;
            state.complementaryCategories = initialState.complementaryCategories;
            state.directCompetitorNames = initialState.directCompetitorNames;
        });
        builder.addCase(loadPortfolio.rejected, (state: PortfolioState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.stores = initialState.stores;
            state.clientRegistration = initialState.clientRegistration;
            state.salesReferenceDate = initialState.salesReferenceDate;
            state.costReferenceDate = initialState.costReferenceDate;
            state.kpmgSpendCategories = initialState.kpmgSpendCategories;
            state.complementaryCategories = initialState.complementaryCategories;
            state.directCompetitorNames = initialState.directCompetitorNames;
        });
        builder.addCase(loadPortfolio.fulfilled, (state: PortfolioState, action: PayloadAction<LoadPortfolioResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.stores = action.payload.stores;
            state.clientRegistration = action.payload.clientRegistration;
            state.salesReferenceDate = action.payload.salesReferenceDate;
            state.costReferenceDate = action.payload.costReferenceDate;
            state.kpmgSpendCategories = action.payload.kpmgSpendCategories;
            state.complementaryCategories = action.payload.complementaryCategories;
            state.directCompetitorNames = action.payload.directCompetitorNames;
        });
        builder.addCase(loadWeeklySales.pending, (state: PortfolioState) => {
            state.weeklySales.isLoading = true;
            state.weeklySales.hasErrors = false;
        });
        builder.addCase(loadWeeklySales.rejected, (state: PortfolioState) => {
            state.weeklySales.isLoading = false;
            state.weeklySales.hasErrors = true;
        });
        builder.addCase(loadWeeklySales.fulfilled, (state: PortfolioState, action: PayloadAction<WeeklySales[]>) => {
            state.weeklySales.data = action.payload;
            state.weeklySales.isLoading = false;
            state.weeklySales.hasErrors = false;
        });
        builder.addCase(loadMonthlySales.pending, (state: PortfolioState) => {
            state.monthlySales.isLoading = true;
            state.monthlySales.hasErrors = false;
        });
        builder.addCase(loadMonthlySales.rejected, (state: PortfolioState) => {
            state.monthlySales.isLoading = false;
            state.monthlySales.hasErrors = true;
        });
        builder.addCase(loadMonthlySales.fulfilled, (state: PortfolioState, action: PayloadAction<MonthlySales[]>) => {
            state.monthlySales.data = action.payload;
            state.monthlySales.isLoading = false;
            state.monthlySales.hasErrors = false;
        });
        builder.addCase(loadYearlyCosts.pending, (state: PortfolioState) => {
            state.yearlyCosts.isLoading = true;
            state.yearlyCosts.hasErrors = false;
        });
        builder.addCase(loadYearlyCosts.rejected, (state: PortfolioState) => {
            state.yearlyCosts.isLoading = false;
            state.yearlyCosts.hasErrors = true;
        });
        builder.addCase(loadYearlyCosts.fulfilled, (state: PortfolioState, action: PayloadAction<YearlyCosts[]>) => {
            state.yearlyCosts.data = action.payload;
            state.yearlyCosts.isLoading = false;
            state.yearlyCosts.hasErrors = false;
        });
        builder.addCase(loadCatchmentCustomerProfiles.pending, (state: PortfolioState) => {
            state.catchmentCustomerProfiles.isLoading = true;
            state.catchmentCustomerProfiles.hasErrors = false;
        });
        builder.addCase(loadCatchmentCustomerProfiles.rejected, (state: PortfolioState) => {
            state.catchmentCustomerProfiles.isLoading = false;
            state.catchmentCustomerProfiles.hasErrors = true;
        });
        builder.addCase(loadCatchmentCustomerProfiles.fulfilled, (state: PortfolioState, action: PayloadAction<CatchmentCustomerProfile[]>) => {
            state.catchmentCustomerProfiles.data = action.payload;
            state.catchmentCustomerProfiles.isLoading = false;
            state.catchmentCustomerProfiles.hasErrors = false;
        });
        builder.addCase(loadCatchmentSpendTotals.pending, (state: PortfolioState) => {
            state.catchmentSpendTotals.isLoading = true;
            state.catchmentSpendTotals.hasErrors = false;
        });
        builder.addCase(loadCatchmentSpendTotals.rejected, (state: PortfolioState) => {
            state.catchmentSpendTotals.isLoading = false;
            state.catchmentSpendTotals.hasErrors = true;
        });
        builder.addCase(loadCatchmentSpendTotals.fulfilled, (state: PortfolioState, action: PayloadAction<CatchmentSpendTotals[]>) => {
            state.catchmentSpendTotals.data = action.payload;
            state.catchmentSpendTotals.isLoading = false;
            state.catchmentSpendTotals.hasErrors = false;
        });
    }
});

export const {
    setSubchaptersIds,
    chooseStoreAndComparator
} = portfolioSlice.actions;

export const loadPortfolio = createAppAsyncThunk(
    "customer/insights/portfolioNew/loadPortfolio",
    async (arg, thunkAPI) => {
        thunkAPI.dispatch(backdropOn());
        try {
            await thunkAPI.dispatch(setupCube());
            const clientRegistrationPromise = thunkAPI.dispatch(loadClientRegistration());
            const salesReferenceDatePromise = thunkAPI.dispatch(loadSalesReferenceDate());
            const kpmgSpendCategoriesPromise = thunkAPI.dispatch(loadKPMGSpendCategories());
            const complementaryCategoriesPromise = thunkAPI.dispatch(loadComplementaryCategories());
            const directCompetitorNamesPromise = thunkAPI.dispatch(loadDirectCompetitorNames());
            const results = await Promise.all([
                clientRegistrationPromise,
                salesReferenceDatePromise,
                kpmgSpendCategoriesPromise,
                complementaryCategoriesPromise,
                directCompetitorNamesPromise
            ]);
            const clientRegistration = results[0];
            const salesReferenceDate = results[1];
            const kpmgSpendCategories = results[2];
            const complementaryCategories = results[3];
            const directCompetitorNames = results[4];
            const catchmentClientId = clientRegistration?.accountId;
            const stores = await thunkAPI.dispatch(loadStores(kpmgSpendCategories, catchmentClientId));
            const costReferenceDate = await thunkAPI.dispatch(loadCostReferenceDate(salesReferenceDate));
            const loadPortfolioResponse: LoadPortfolioResponse = {
                stores,
                clientRegistration,
                salesReferenceDate,
                costReferenceDate,
                kpmgSpendCategories,
                complementaryCategories,
                directCompetitorNames
            };
            return loadPortfolioResponse;
        } catch (error) {
            thunkAPI.dispatch(notifyError("Error loading Portfolio."));
            return thunkAPI.rejectWithValue(null);
        } finally {
            thunkAPI.dispatch(backdropOff());
        }
    }
);

export const clearPortfolio = (): AppThunk => async (dispatch) => {
    dispatch(portfolioSlice.actions.clearStores());
    dispatch(portfolioSlice.actions.clearClientRegistration());
    dispatch(portfolioSlice.actions.clearSalesReferenceDate());
    dispatch(portfolioSlice.actions.clearCostReferenceDate());
    dispatch(portfolioSlice.actions.clearKPMGSpendCategories());
    dispatch(portfolioSlice.actions.clearStoreAndComparator());
    dispatch(portfolioSlice.actions.clearDirectCompetitorNames());
};

export const loadInsights = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const selectedStore = selectStore(state);
        const comparatorStores = selectComparator(state)?.getStores();
        const referenceDate = selectSalesReferenceDate(state);
        const costReferenceDate = selectCostReferenceDate(state);
        const spendCategories = selectKPMGSpendCategories(state);
        const catchmentAccountId = selectClientRegistration(state)?.accountId ?? "";

        dispatch(loadOverview());
        dispatch(loadWeeklySales({ selectedStore, comparatorStores, referenceDate }));
        const monthlySalesPromise = dispatch(loadMonthlySales({ selectedStore, comparatorStores, referenceDate }));
        dispatch(loadYearlyCosts({ selectedStore, comparatorStores, costReferenceDate }));
        dispatch(loadCatchmentCustomerProfiles({ selectedStore, catchmentAccountId }));
        dispatch(loadCatchment());
        dispatch(loadCatchmentSpendTotals({ selectedStore, comparatorStores, spendCategories, catchmentAccountId }));
        dispatch(loadCompetition());
        dispatch(loadAreaHealth());
        dispatch(loadFootfall());

        await monthlySalesPromise;
        dispatch(loadRevenue());
    } catch (error) {
        dispatch(logError("Error loading Insights.", error));
    }
};

export const clearInsights = (): AppThunk => (dispatch) => {
    dispatch(portfolioSlice.actions.clearWeeklySales());
    dispatch(portfolioSlice.actions.clearMonthlySales());
    dispatch(portfolioSlice.actions.clearYearlyCosts());
    dispatch(clearRevenue());
    dispatch(clearCatchment());
    dispatch(clearCompetition());
    dispatch(clearAreaHealth());
    dispatch(clearFootfall());
};

export const selectSubchaptersIds = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.subchaptersIds;
};

export const selectStores = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.stores;
};

export const selectClientRegistration = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.clientRegistration;
};

export const selectSalesReferenceDate = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.salesReferenceDate;
};

export const selectCostReferenceDate = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.costReferenceDate;
};

export const selectKPMGSpendCategories = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.kpmgSpendCategories;
};

export const selectComplementaryCategories = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.complementaryCategories;
};

export const selectNumberOfProductCategories = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.numberOfProductCategories;
};

export const selectStore = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.store;
};

export const selectComparator = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.comparator;
};

export const selectDirectCompetitorNames = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.directCompetitorNames;
};

export const selectWeeklySales = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.weeklySales;
};

export const selectMonthlySales = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.monthlySales;
};

export const selectCatchmentCustomerProfiles = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.catchmentCustomerProfiles;
};

export const selectYearlyCosts = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.yearlyCosts;
};

export const selectCatchmentSpendTotals = (state: RootState) => {
    return state.customer.insights.portfolioNew.root.catchmentSpendTotals;
};

export const selectIsSetupComplete = createSelector(
    selectStore,
    selectComparator,
    (store, comparator) => {
        return !!store && !!comparator;
    }
);

export const selectShowSimilarityScores = createSelector(
    selectStores,
    (stores) => {
        return stores.some(store => store.similarStores.length !== 0); 
    }
);

export default portfolioSlice;
