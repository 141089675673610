import React from "react";

import { Box, Typography } from "@material-ui/core";

import AgeStructure from "./AgeStructure";
import ChildrenAgeStructure from "./ChildrenAgeStructure";
import CountryOfBirth from "./CountryOfBirth";
import DemographicIndicatorDropdown from "./DemographicIndicatorDropdown";
import DemographicIndicatorMeasure from "./DemographicIndicatorMeasure";
import DwellingType from "./DwellingType";
import Ethnicity from "./Ethnicity";
import FirstLanguage from "./FirstLanguage";
import HouseholdIncome from "./HouseholdIncome";
import HouseholdNumberOfCars from "./HouseholdNumberOfCars";
import HouseholdOccupancy from "./HouseholdOccupancy";
import HouseholdTenure from "./HouseholdTenure";
import PopulationDensity from "./PopulationDensity";

import { useAppSelector } from "store";
import { DemographicIndicator } from "modules/customer/tools/location/demographics/catchmentDemographics";
import { selectDemographicIndicator } from "modules/customer/tools/location/demographics/demographicsSlice";
import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";

const BreakdownOfCatchmentAreaDemographic: React.FC = () => {
    const comparatorsByChapter = useAppSelector(selectComparatorsByChapter);
    const comparatorName = comparatorsByChapter?.demographics.storeName;
    const demographicIndicator = useAppSelector(selectDemographicIndicator);

    return (
        <Box data-cy="breakdown-of-catchment-area-demographic">
            <Typography variant="h6" component="div" gutterBottom>
                Breakdown of catchment area demography for the selected location vs your {comparatorName} store
            </Typography>
            <DemographicIndicatorDropdown />
            <DemographicIndicatorMeasure />
            {(() => {
                switch (demographicIndicator) {
                    case DemographicIndicator.DwellingType:
                        return <DwellingType />;
                    case DemographicIndicator.HouseholdOccupancy:
                        return <HouseholdOccupancy />;
                    case DemographicIndicator.HouseholdIncome:
                        return <HouseholdIncome />;
                    case DemographicIndicator.HouseholdTenure:
                        return <HouseholdTenure />;
                    case DemographicIndicator.AgeStructure:
                        return <AgeStructure />;
                    case DemographicIndicator.ChildrenAgeStructure:
                        return <ChildrenAgeStructure />;
                    case DemographicIndicator.CountryOfBirth:
                        return <CountryOfBirth />;
                    case DemographicIndicator.Ethnicity:
                        return <Ethnicity />;
                    case DemographicIndicator.FirstLanguage:
                        return <FirstLanguage />;
                    case DemographicIndicator.HouseholdNumberOfCars:
                        return <HouseholdNumberOfCars />;
                    case DemographicIndicator.PopulationDensity:
                        return <PopulationDensity />;
                    default:
                        return <></>;
                }
            })()}
        </Box>
    );
};

export default BreakdownOfCatchmentAreaDemographic;
