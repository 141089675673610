import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { apiGet, apiPost, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError, notifyInfo, notifySuccess } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface Subscription {
    planName: string,
    priceInPence: number,
    numberOfUsers: number,
    dataRefreshFrequency: string,
    renewalDate: DateTime
}

interface CallbackVisibility {
    isVisible: boolean
}

interface CallbackForm {
    name: string,
    subject: string,
    description: string,
    phoneNumber: string,
    errors: CallbackFormErrors
}

interface CallbackFormErrors {
    name: string,
    subject: string,
    description: string,
    phoneNumber: string
}

interface BillingState {
    subscription: Subscription,
    callbackVisibility: CallbackVisibility,
    callbackForm: CallbackForm
}

const subscriptionEmpty: Subscription = {
    planName: "",
    priceInPence: 0,
    numberOfUsers: 0,
    dataRefreshFrequency: "",
    renewalDate: DateTime.fromMillis(0, { zone: "utc" })
};

const callbackVisibilityEmpty: CallbackVisibility = {
    isVisible: false
};

const callbackFormErrorsEmpty: CallbackFormErrors = {
    name: "",
    subject: "",
    description: "",
    phoneNumber: ""
};

const callbackFormEmpty: CallbackForm = {
    name: "",
    subject: "",
    description: "",
    phoneNumber: "",
    errors: callbackFormErrorsEmpty
};

const initialState: BillingState = {
    subscription: subscriptionEmpty,
    callbackVisibility: callbackVisibilityEmpty,
    callbackForm: callbackFormEmpty
};

const billingSlice = createSlice({
    name: "customer/account/billing",
    initialState,
    reducers: {
        setSubscription: (state, action: PayloadAction<Subscription>) => {
            state.subscription = action.payload;
        },
        clearSubscription: (state) => {
            state.subscription = subscriptionEmpty;
        },
        showCallback: (state) => {
            state.callbackVisibility.isVisible = true;
        },
        hideCallback: (state) => {
            state.callbackVisibility = callbackVisibilityEmpty;
        },
        setCallbackForm: (state, action: PayloadAction<CallbackForm>) => {
            state.callbackForm = action.payload;
        },
        clearCallbackForm: (state) => {
            state.callbackForm = callbackFormEmpty;
        }
    }
});

export const {
    showCallback,
    hideCallback,
    setCallbackForm,
    clearCallbackForm
} = billingSlice.actions;

export const getSubscription = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/account/billing/subscription"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const subscription = response.data.subscription;
            dispatch(billingSlice.actions.setSubscription({
                ...subscription,
                renewalDate: DateTime.fromISO(subscription.renewalDate, { zone: "utc" })
            }));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(billingSlice.actions.clearSubscription());
            dispatch(notifyError("Account not found."));
            break;
        }
        default: {
            dispatch(billingSlice.actions.clearSubscription());
            break;
        }
    }
};

export const requestCallback = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const form = selectCallbackForm(state);
    const response = await dispatch(apiPost("/customer/account/billing/callback", form));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(billingSlice.actions.hideCallback());
            dispatch(billingSlice.actions.clearCallbackForm());
            dispatch(notifySuccess("Thank you. You've successfully requested a callback."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            const errors = {
                name: response.errorData?.errors?.name?.[0],
                subject: response.errorData?.errors?.subject?.[0],
                description: response.errorData?.errors?.description?.[0],
                phoneNumber: response.errorData?.errors?.phoneNumber?.[0]
            };
            dispatch(billingSlice.actions.setCallbackForm({ ...form, errors }));
            break;
        }
    }
};

export const downloadPlanDetails = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/account/billing/plan-details"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const url = response.data.url;
            window.open(url, "_blank");
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyInfo("Plan details file not ready. Please contact support."));
            break;
        }
    }
};

export const downloadTermsOfUse = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/account/billing/terms-of-use"));
    if (response.status === ApiResponseStatus.Ok) {
        const url = response.data.url;
        window.open(url, "_blank");
    }
};

export const createCustomerPortalSession = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiPost("/customer/account/billing/customer-portal-session", {}));
    if (response.status === ApiResponseStatus.Ok) {
        const customerPortalSession = response.data.customerPortalSession;
        const url = customerPortalSession.url;
        window.open(url, "_blank");
    }
};

export const selectSubscription = (state: RootState) => {
    return state.customer.account.billing.subscription;
};

export const selectCallbackVisibility = (state: RootState) => {
    return state.customer.account.billing.callbackVisibility;
};

export const selectCallbackForm = (state: RootState) => {
    return state.customer.account.billing.callbackForm;
};

export const selectCanRequestCallback = createSelector(
    selectCallbackForm,
    (callbackForm) => {
        const allFilled = !!callbackForm.name
            && !!callbackForm.subject
            && !!callbackForm.phoneNumber;

        const hasErrors = !!callbackForm.errors.name
            || !!callbackForm.errors.subject
            || !!callbackForm.errors.description
            || !!callbackForm.errors.phoneNumber;

        return allFilled && !hasErrors;
    }
);

export default billingSlice;
