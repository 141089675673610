import React from "react";
import { Expression, FillPaint } from "mapbox-gl";
import { Layer, LayerProps, MapEvent, MapRef, Source, SourceProps, ViewportProps } from "react-map-gl";
import _ from "lodash";

import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import MapBase from "components/visuals/maps/MapBase";
import useColourPalette from "components/visuals/useColourPalette";
import Header from "pages/customer/tools/location/Header";
import PinnedLocationMarker from "pages/customer/tools/location/map/PinnedLocation";
import RetailCentresVisibilityFilter from "pages/customer/tools/location/map/RetailCentresVisibilityFilter";

import Alignment from "./Alignment";
import Legend from "./Legend";
import Pin from "./Pin";

import { useAppDispatch, useAppSelector } from "store";
import {
    choosePinnedLocation,
    loadAddress,
    selectLocalAuthorities,
    selectPinnedLocation,
    selectRetailCentresWithScoreGroups,
    selectViewport,
    setViewport,
    selectStores,
    selectShowExistingStores
} from "modules/customer/tools/location/locationSlice";
import { PinnedLocation } from "modules/customer/tools/location/pinnedLocation";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import ExistingStore from "pages/customer/tools/location/map/ExistingStore";

const LOCAL_AUTHORITIES_LAYER_ID = "local-authorities";
const LOCAL_AUTHORITIES_LAYER_MIN_ZOOM = 4;
const LOCAL_AUTHORITIES_LAYER_MAX_ZOOM = 13;

const RETAIL_CENTRES_FILL_LAYER_ID = "retail-centres-fill";
const RETAIL_CENTRES_LINE_LAYER_ID = "retail-centres-line";
const RETAIL_CENTRES_LAYER_MIN_ZOOM = 13;

interface PointerInfo {
    localAuthorityCode?: string,
    retailCentreCode?: string,
    latitude: number,
    longitude: number
}

const pointerInfoEmpty: PointerInfo = {
    localAuthorityCode: undefined,
    retailCentreCode: undefined,
    latitude: 0,
    longitude: 0
};

const handlePointerEvent = (event: MapEvent): PointerInfo => {
    const { features, lngLat } = event;
    const localAuthorityCode = features?.find(f => f.layer.id === LOCAL_AUTHORITIES_LAYER_ID)?.properties["geo_code"];
    const retailCentreCode = features?.find(f => f.layer.id === RETAIL_CENTRES_FILL_LAYER_ID)?.properties["geo_code"];
    return {
        localAuthorityCode,
        retailCentreCode,
        latitude: lngLat[1],
        longitude: lngLat[0]
    };
};

const createMapSource = (tilesetUrl: string): SourceProps => {
    return {
        type: "vector",
        url: tilesetUrl
    };
};

const createMapFillLayer = (id: string, tilesetId: string, fillColor: string | Expression, fillOpacity: number | Expression, beforeId: string, minZoom?: number, maxZoom?: number): LayerProps => {
    const paint: FillPaint = {
        "fill-color": fillColor,
        "fill-opacity": fillOpacity
    };
    const layer: LayerProps = {
        id: id,
        type: "fill",
        "source-layer": tilesetId,
        beforeId,
        minzoom: 0,
        maxzoom: 24,
        paint
    };
    if (minZoom) {
        layer.minzoom = minZoom;
    }
    if (maxZoom) {
        layer.maxzoom = maxZoom;
    }
    return layer;
};

const createMapLineLayer = (id: string, tilesetId: string, fillColor: string | Expression, fillOpacity: number | Expression, lineWidth: number, beforeId: string, minZoom?: number, maxZoom?: number): LayerProps => {
    const paint = {
        "line-color": fillColor,
        "line-opacity": fillOpacity,
        "line-width": lineWidth,
    };
    const layer: LayerProps = {
        id: id,
        type: "line",
        "source-layer": tilesetId,
        beforeId,
        minzoom: 0,
        maxzoom: 24,
        paint
    };
    if (minZoom) {
        layer.minzoom = minZoom;
    }
    if (maxZoom) {
        layer.maxzoom = maxZoom;
    }
    return layer;
};

const getVisibleAreasCodes = (map: any, layerId: string) => {
    const layer = map.getLayer(layerId);
    if (!layer) {
        return [];
    }
    // @ts-ignore //ToDo: update react-map-gl version to use type MapboxMap
    return map.queryRenderedFeatures({ layers: [layerId] }).map(feature => feature.properties["geo_code"]);
};

const createFillColorExpression = (areas: {
    code: string,
    scoreGroup: number
}[], colours: string[]): string | Expression => {
    let fillColor: string | Expression = "rgba(0,0,0,0)";
    if (areas.length > 0) {
        fillColor = ["match", ["get", "geo_code"]];
        for (const area of areas) {
            fillColor.push(area.code, colours[area.scoreGroup]);
        }
        fillColor.push("rgba(0, 0, 0, 0)");
    }
    return fillColor;
};

const createFillOpacityExpression = (defaultFillOpacity: number, onHoverOpactity: number, hoverAreaCode?: string, clickAreaCode?: string,): number | Expression => {
    let fillOpacity: number | Expression = defaultFillOpacity;
    if (!hoverAreaCode && !clickAreaCode) {
        return fillOpacity;
    }
    fillOpacity = ["match", ["get", "geo_code"]];
    if (hoverAreaCode) {
        fillOpacity.push(hoverAreaCode, onHoverOpactity);
    }
    if (clickAreaCode && clickAreaCode !== hoverAreaCode) {
        fillOpacity.push(clickAreaCode, onHoverOpactity);
    }
    fillOpacity.push(defaultFillOpacity);
    return fillOpacity;
};

const getPinSize = (zoomLevel: number | undefined) => {
    if (zoomLevel) {
        if (zoomLevel > 10) return 24;
        if (zoomLevel > 7) return 18;
        return 12;
    } else {
        return 24;
    }
};

const initialViewport: ViewportProps = {
    latitude: 54.093409,
    longitude: -2.89479,
    zoom: 5.5,
    minZoom: 4
};

const Map: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const viewport = useAppSelector(selectViewport);
    const mapRef = React.useRef<MapRef>(null);
    const geocoderContainerRef = React.useRef<HTMLDivElement>(null);
    const [hoverInfo, setHoverInfo] = React.useState<PointerInfo>(pointerInfoEmpty);

    const retailCentre = useAppSelector(selectPinnedLocation)?.retailCentre;

    // LocalAuthorities - start
    const localAuthorities = useAppSelector(selectLocalAuthorities);

    const localAuthoritiesSource = React.useMemo(() => {
        return createMapSource(mapboxConfiguration.localAuthoritiesTilesetUrl);
    }, [mapboxConfiguration]);

    const localAuthoritiesLayer = React.useMemo(() => {
        const colours = colourPalette.heatmap;
        const fillColor = createFillColorExpression(localAuthorities, colours);
        const defaultFillOpacity = 0.4;
        const onHoverOpactity = 0.15;
        const fillOpacity = createFillOpacityExpression(defaultFillOpacity, onHoverOpactity, hoverInfo.localAuthorityCode);
        const beforeId = "tunnel-simple";
        return createMapFillLayer(
            LOCAL_AUTHORITIES_LAYER_ID,
            mapboxConfiguration.localAuthoritiesTilesetId,
            fillColor,
            fillOpacity,
            beforeId,
            LOCAL_AUTHORITIES_LAYER_MIN_ZOOM,
            LOCAL_AUTHORITIES_LAYER_MAX_ZOOM);
    }, [mapboxConfiguration, colourPalette, localAuthorities, hoverInfo]);
    // LocalAuthorities - finish

    // RetailCentres - start
    const retailCentres = useAppSelector(selectRetailCentresWithScoreGroups);
    const [visibleRetailCentresCodes, setVisibleRetailCentresCodes] = React.useState<string[]>([]);

    const visibleRetailCentres = React.useMemo(() => {
        return retailCentres.filter(retailCentre => visibleRetailCentresCodes.includes(retailCentre.code));
    }, [retailCentres, visibleRetailCentresCodes]);

    const retailCentresSource = React.useMemo(() => {
        return createMapSource(mapboxConfiguration.retailCentresTilesetUrl);
    }, [mapboxConfiguration]);

    const retailCentresFillLayer = React.useMemo(() => {
        const colours = colourPalette.heatmap;
        const fillColor = createFillColorExpression(visibleRetailCentres, colours);
        const defaultFillOpacity = 0.4;
        const onHoverOpactity = 0;
        const fillOpacity = createFillOpacityExpression(defaultFillOpacity, onHoverOpactity, hoverInfo.retailCentreCode, retailCentre?.code);
        const beforeId = "tunnel-simple";
        return createMapFillLayer(
            RETAIL_CENTRES_FILL_LAYER_ID,
            mapboxConfiguration.retailCentresTilesetId,
            fillColor,
            fillOpacity,
            beforeId,
            RETAIL_CENTRES_LAYER_MIN_ZOOM);
    }, [mapboxConfiguration, colourPalette, visibleRetailCentres, hoverInfo, retailCentre]);

    const retailCentresLineLayer = React.useMemo(() => {
        const colours = colourPalette.heatmap;
        const fillColor = createFillColorExpression(visibleRetailCentres, colours);
        const fillOpacity = createFillOpacityExpression(0, 1, hoverInfo.retailCentreCode, retailCentre?.code);
        const lineWidth = 3;
        const beforeId = "road-label-simple";
        return createMapLineLayer(
            RETAIL_CENTRES_LINE_LAYER_ID,
            mapboxConfiguration.retailCentresTilesetId,
            fillColor,
            fillOpacity,
            lineWidth,
            beforeId,
            RETAIL_CENTRES_LAYER_MIN_ZOOM);
    }, [mapboxConfiguration, colourPalette, visibleRetailCentres, hoverInfo, retailCentre]);

    const existingStores = useAppSelector(selectStores);
    const showExistingStores = useAppSelector(selectShowExistingStores);

    const visibleExistingStores = showExistingStores ? existingStores : []; 

    const handleViewportChange = React.useCallback((newViewport: ViewportProps) => {
        dispatch(setViewport(newViewport));
        const map = mapRef?.current?.getMap();
        if (!map) {
            return;
        }
        const rcVisibleCodes = getVisibleAreasCodes(map, RETAIL_CENTRES_FILL_LAYER_ID as string);
        setVisibleRetailCentresCodes(rcVisibleCodes);
    }, [dispatch, mapRef, setVisibleRetailCentresCodes]);

    const handleViewportChangeDelayed = React.useMemo(() => {
        return _.debounce((newViewport: ViewportProps) => handleViewportChange(newViewport), 500);
    }, [handleViewportChange]);

    const handleHover = React.useCallback((event: MapEvent) => {
        const pointerInfo = handlePointerEvent(event);
        setHoverInfo(pointerInfo);
    }, [setHoverInfo]);

    const handleHoverDelayed = React.useMemo(() => {
        return _.debounce((event: MapEvent) => handleHover(event), 400);
    }, [handleHover]);

    const handleClick = React.useCallback(async (event: MapEvent, centerMap: (latitude: number, longitude: number, zoom?: number) => void) => {
        const pointerInfo = handlePointerEvent(event);

        if (pointerInfo.retailCentreCode && pointerInfo.retailCentreCode !== retailCentre?.code) {
            const newRetailCentre = retailCentres.find(rc => rc.code === pointerInfo.retailCentreCode);
            if (newRetailCentre) {
                const address = await dispatch(loadAddress(pointerInfo.latitude, pointerInfo.longitude));
                const pinnedLocation = new PinnedLocation(pointerInfo.latitude, pointerInfo.longitude, address, newRetailCentre);
                dispatch(choosePinnedLocation(pinnedLocation));
            }
        }

        if (pointerInfo.localAuthorityCode) {
            centerMap(pointerInfo.latitude, pointerInfo.longitude, RETAIL_CENTRES_LAYER_MIN_ZOOM);
            setTimeout(() => {
                const map = mapRef?.current?.getMap();
                const rcVisibleCodes = getVisibleAreasCodes(map, RETAIL_CENTRES_FILL_LAYER_ID as string);
                setVisibleRetailCentresCodes(rcVisibleCodes);
            }, 3500);
        }
    }, [retailCentre, retailCentres, dispatch]);

    const handleMouseOut = React.useCallback(() => {
        handleHoverDelayed.cancel();
        setHoverInfo(pointerInfoEmpty);
    }, [handleHoverDelayed, setHoverInfo]);

    return (
        <>
            <Box position="absolute" width={theme.spacing(55)} margin={2} zIndex={theme.zIndex.drawer}>
                <Header />
                {/* @ts-ignore */}
                <Box width="100%" ref={geocoderContainerRef} paddingTop={1} data-cy="geocoder" />
                <Alignment />
            </Box>
            <MapBase
                loading={false}
                error={false}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapRef={mapRef}
                initialViewport={initialViewport}
                currentViewport={viewport}
                addGeocoder={true}
                geocoderContainerRef={geocoderContainerRef}
                addNavigationControl={true}
                addRecenterButton={true}
                addFullscreenButton={true}
                height="100vh"
                interactiveLayerIds={[LOCAL_AUTHORITIES_LAYER_ID, RETAIL_CENTRES_FILL_LAYER_ID, RETAIL_CENTRES_LINE_LAYER_ID]}
                onViewportChange={handleViewportChangeDelayed}
                onHover={handleHoverDelayed}
                onClick={handleClick}
                onMouseOut={handleMouseOut}
                dataCy="landing-map"
                displayPOIs={true}
            >
                {visibleExistingStores.map(store =>
                    <ExistingStore
                        key={store.id}
                        latitude={store.latitude}
                        longitude={store.longitude}
                        size={getPinSize(viewport?.zoom)}
                    />
                )}
                <Source {...localAuthoritiesSource}>
                    <Layer {...localAuthoritiesLayer} />
                </Source>
                <Source {...retailCentresSource}>
                    <Layer {...retailCentresFillLayer} />
                    <Layer {...retailCentresLineLayer} />
                </Source>
                <PinnedLocationMarker />
            </MapBase>
            <Pin />
            <Legend />
            <RetailCentresVisibilityFilter />
        </>
    );
};

export default Map;
