import { AppThunk } from "appThunk";
import { ExtendedResultSet, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { SpendCategory } from "./spendCategory";

export class SpendLevel {
    public readonly outputAreaCode: string;
    public readonly categoryName: string;
    public readonly weightedSpend: number;
    public readonly spendPerHead: number;
    public readonly customerProfile: string;

    constructor(
        outputAreaCode: string,
        categoryName: string,
        weightedSpend: number,
        spendPerHead: number,
        customerProfile: string
    ) {
        this.outputAreaCode = outputAreaCode;
        this.categoryName = categoryName;
        this.weightedSpend = weightedSpend;
        this.spendPerHead = spendPerHead;
        this.customerProfile = customerProfile;
    }
}

interface CatchmentSpendDimensions {
    "CatchmentMarketCategorySpend.OAID": string,
    "CatchmentMarketCategorySpend.SpendKpmgCategory": string,
    "CatchmentMarketCategorySpend.BaselineWeightedSpend": number,
    "CatchmentMarketCategorySpend.CategorySpendPerHead": number
    "D_LocationOutputArea.SupergroupName": string
}

export const loadSpendLevels = (retailCentreId: number | undefined, targetStoreCategoryId: number | undefined, spendCategories: SpendCategory[], scenarioCatchmentAccountId: string): AppThunk<Promise<SpendLevel[]>> => async (dispatch) => {
    if (!retailCentreId || !targetStoreCategoryId) {
        return [];
    }
    try {
        const spendCategoryNames = spendCategories.map(category => category.name);

        const retailCentreIdString = retailCentreId.toString();
        const query = {
            dimensions: [
                "CatchmentMarketCategorySpend.OAID",
                "CatchmentMarketCategorySpend.SpendKpmgCategory",
                "CatchmentMarketCategorySpend.BaselineWeightedSpend",
                "CatchmentMarketCategorySpend.CategorySpendPerHead",
                "D_LocationOutputArea.SupergroupName"
            ],
            filters: [{
                member: "CatchmentMarketCategorySpend.RetailCentreID",
                operator: "equals",
                values: [retailCentreIdString]
            }, {
                member: "CatchmentMarketCategorySpend.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategoryId)]
            }, {
                member: "CatchmentMarketCategorySpend.Client_ID",
                operator: "equals",
                values: [scenarioCatchmentAccountId]
            }, {
                member: "CatchmentMarketCategorySpend.SpendKpmgCategory",
                operator: "equals",
                values: spendCategoryNames
            }],
            segments: [
                "CatchmentMarketCategorySpend.Scenario"
            ],
            order: [
                ["CatchmentMarketCategorySpend.OAID", "asc"]
            ]
        };
        const resultSet = await dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet<CatchmentSpendDimensions>;
        const rawData = resultSet.loadResponses[0].data;

        return rawData.map(row => {
            const outputAreaCode = row["CatchmentMarketCategorySpend.OAID"];
            const categoryName = row["CatchmentMarketCategorySpend.SpendKpmgCategory"];
            const weightedSpend = Number(row["CatchmentMarketCategorySpend.BaselineWeightedSpend"]);
            const spendPerHead = Number(row["CatchmentMarketCategorySpend.CategorySpendPerHead"]);
            const customerProfile = row["D_LocationOutputArea.SupergroupName"];
            return new SpendLevel(outputAreaCode, categoryName, weightedSpend, spendPerHead, customerProfile);
        });
    } catch (error) {
        dispatch(logError("Error loading SpendLevels.", error));
        throw error;
    }
};
