import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectDemographicIndicatorsInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";

const AgeStructure: React.FC = () => {
    const title = "Age structure (Age)";
    const categories = [
        "=<16",
        "17-25",
        "26-35",
        "36-45",
        "46-55",
        "56-65",
        "66+"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectDemographicIndicatorsInCatchmentArea);

    const retailCentre = demographicIndicatorsInCatchmentArea.retailCentre?.ageStructure;
    const retailCentreValues = retailCentre ? [
        retailCentre.age0to16,
        retailCentre.age17to25,
        retailCentre.age26to35,
        retailCentre.age36to45,
        retailCentre.age46to55,
        retailCentre.age56to65,
        retailCentre.age66Plus
    ] : [];
    const retailCentreTotal = retailCentreValues.reduce((sum, val) => sum + val, 0);

    const benchmark = demographicIndicatorsInCatchmentArea.benchmark?.ageStructure;
    const benchmarkValues = benchmark ? [
        benchmark.age0to16,
        benchmark.age17to25,
        benchmark.age26to35,
        benchmark.age36to45,
        benchmark.age46to55,
        benchmark.age56to65,
        benchmark.age66Plus
    ] : [];
    const benchmarkTotal = benchmarkValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            retailCentreValues={retailCentreValues}
            benchmarkValues={benchmarkValues}
            retailCentreTotal={retailCentreTotal}
            benchmarkTotal={benchmarkTotal}
        />
    );
};

export default AgeStructure;
