import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Button, MenuItem, TextField, Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import SimpleDialog from "components/SimpleDialog";

import { operations, selectors } from "modules/customer/account/users";

const UserRole = (props) => {
    const { id, role } = props;
    const dispatch = useDispatch();
    const user = useSelector(state => selectors.user(state));
    const editUserVisibility = useSelector(state => selectors.editUserVisibility(state));
    const show = (editUserVisibility.isVisible) && (id === editUserVisibility.id);
    const [newRole, setNewRole] = React.useState(role);
    const newRoleLabel = (() => {
        switch (newRole) {
            case "Admin":
                return "an Admin";
            case "Editor":
                return "an Editor";
            case "Viewer":
            default:
                return "a Viewer";
        }
    })();
    const newRoleMessage = (() => {
        switch (role) {
            case "Admin":
                return newRole === "Editor"
                    ? "will no longer be able to manage users or billing" //admin down to editor
                    : "will no longer be able to manage data, users or billing"; //admin down to viewer
            case "Editor":
                return newRole === "Admin"
                    ? "will be able to manage data, users and billing" //editor up to admin
                    : "will no longer be able to manage data"; //editor down to viewer
            case "Viewer":
            default:
                return newRole === "Admin"
                    ? "will be able to manage data, users and billing" //viewer up to admin
                    : "will be able to manage data"; //viewer up to editor
        }
    })();

    React.useEffect(() => {
        if (show) {
            dispatch(operations.getUser(id));
        }
    }, [dispatch, show, id]);

    const handleConfirmClick = () => {
        dispatch(operations.changeUserRole(newRole));
    };

    const handleCancelClick = () => {
        dispatch(operations.hideEditUser());
        dispatch(operations.clearUser());
    };

    const handleRoleChange = (event) => {
        const value = event.target.value;
        setNewRole(value);
        dispatch(operations.showEditUser(id));
    };

    if (role === "Owner") {
        return (
            <Typography variant="subtitle1" component="div">
                {role}
            </Typography>
        );
    }

    return (
        <>
            <TextField
                variant="outlined"
                size="small"
                color="secondary"
                margin="dense"
                select
                value={role}
                onChange={handleRoleChange}
                fullWidth
            >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Editor">Editor</MenuItem>
                <MenuItem value="Viewer">Viewer</MenuItem>
            </TextField>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <>
                        <Typography variant="h1" component="div" color="error" gutterBottom>
                            <ErrorOutlineIcon fontSize="inherit" color="inherit" />
                        </Typography>
                        <Typography variant="h5" component="div" gutterBottom>
                            Are you sure you want to make this user {newRoleLabel}?
                        </Typography>
                    </>
                }
                titleAlign="center"
                content={
                    <Typography variant="subtitle1" component="div">
                        {user.firstName} {user.lastName} {newRoleMessage}
                    </Typography>
                }
                contentAlign="center"
                actions={
                    <>
                        <Button
                            variant="outlined"
                            size="large"
                            color="default"
                            disableElevation
                            onClick={handleCancelClick}
                        >
                            No, cancel
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            disableElevation
                            onClick={handleConfirmClick}
                        >
                            Yes, make {newRole}
                        </Button>
                    </>
                }
                actionsJustifyContent="center"
            />
        </>
    );
};

UserRole.propTypes = {
    id: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
};

export default UserRole;
