import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import {
    selectCatchmentAccountIds,
    selectCatchmentCustomerProfiles,
    selectCatchmentPopulation,
    selectComparatorsByChapter, 
    selectHasErrors as selectLocationHasErrors,
    selectIsLoading as selectLocationIsLoading,
    selectPinnedLocation,
    selectTarget,
    selectTargetStoreCategory,
    selectTargetSpendCategories
} from "modules/customer/tools/location/locationSlice";
import { ScoreField } from "modules/customer/tools/location/retailCentre";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";

import { loadSpendPerOutputArea, SpendPerOutputArea } from "./spendPerOutputArea";
import { SpendCategory } from "./spendCategory";
import { loadSpendPerDetailedCategory, SpendPerDetailedCategory } from "./spendPerDetailedCategory";
import { numberSortExpression, SortDirection, stringSortExpression } from "utils/sortUtils";

interface LoadSpendResponse {
    spendPerOutputArea: SpendPerOutputArea[],
    spendPerDetailedCategory: SpendPerDetailedCategory[],
    spendCategories: SpendCategory[]
}

export interface SpendByCategory {
    categoryName: string,
    spend: number,
    spendPerHead?: number
}

interface SpendState {
    isLoading: boolean,
    hasErrors: boolean,
    spendPerOutputArea: SpendPerOutputArea[],
    spendPerDetailedCategory: SpendPerDetailedCategory[],
    spendCategories: SpendCategory[],
    weightSpendByProbability: boolean
}

const initialState: SpendState = {
    isLoading: false,
    hasErrors: false,
    spendPerOutputArea: [],
    spendPerDetailedCategory: [],
    spendCategories: [],
    weightSpendByProbability: true,
};

const spendSlice = createSlice({
    name: "customer/tools/location/spendNew",
    initialState,
    reducers: {
        toggleSpendCategoryIsSelected: (state, action: PayloadAction<string>) => {
            const spendCategoryName = action.payload;
            state.spendCategories.find(spendCategory => spendCategory.name === spendCategoryName)?.toggleIsSelected();
        },
        chooseAllSpendCategories: (state) => {
            state.spendCategories.forEach(spendCategory => spendCategory.setIsSelected(true));
        },
        deselectAllSpendCategories: (state) => {
            state.spendCategories.forEach(spendCategory => spendCategory.setIsSelected(false));
        },
        toggleWeightSpendByProbability: (state) => {
            state.weightSpendByProbability = !state.weightSpendByProbability;
        },
        clearWeightSpendByProbability: (state) => {
            state.weightSpendByProbability = initialState.weightSpendByProbability;
        },
        clearSpendPerOutputArea: (state) => {
            state.spendPerOutputArea = initialState.spendPerOutputArea;
        },
        clearSpendPerDetailedCategory: (state) => {
            state.spendPerDetailedCategory = initialState.spendPerDetailedCategory;
        },
        clearSpendCategories: (state) => {
            state.spendCategories = initialState.spendCategories;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadSpend.pending, (state: SpendState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadSpend.rejected, (state: SpendState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.spendCategories = initialState.spendCategories;
            state.spendPerOutputArea = initialState.spendPerOutputArea;
            state.spendPerDetailedCategory = initialState.spendPerDetailedCategory;
        });
        builder.addCase(loadSpend.fulfilled, (state: SpendState, action: PayloadAction<LoadSpendResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.spendCategories = action.payload.spendCategories;
            state.spendPerOutputArea = action.payload.spendPerOutputArea;
            state.spendPerDetailedCategory = action.payload.spendPerDetailedCategory;
        });
    }
});

export const {
    toggleSpendCategoryIsSelected,
    chooseAllSpendCategories,
    deselectAllSpendCategories,
    toggleWeightSpendByProbability,
} = spendSlice.actions;

export const loadSpend = createAppAsyncThunk(
    "customer/tools/location/spendNew/loadSpend",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const retailCentreId = selectPinnedLocation(state)?.retailCentre.id;
            const comparator = selectComparatorsByChapter(state)?.spend;
            const targetStoreCategoryId = selectTargetStoreCategory(state)?.id;
            const catchmentAccountIds = selectCatchmentAccountIds(state);
            const targetSpendCategories = selectTargetSpendCategories(state);

            const spendCategories = targetSpendCategories.map(spendCategory => (
                new SpendCategory(spendCategory.name, spendCategory.id, true)
            ));

            const spendPerOutputAreaPromise = thunkAPI.dispatch(loadSpendPerOutputArea(retailCentreId, targetStoreCategoryId, spendCategories, catchmentAccountIds.scenario));
            const spendPerDetailedCategoryPromise = thunkAPI.dispatch(loadSpendPerDetailedCategory(retailCentreId, comparator, targetStoreCategoryId, spendCategories, catchmentAccountIds));
            const results = await Promise.all([spendPerOutputAreaPromise, spendPerDetailedCategoryPromise]);

            const spendPerOutputArea = results[0];
            const spendPerDetailedCategory = results[1];

            const loadSpendResponse: LoadSpendResponse = {
                spendPerOutputArea,
                spendPerDetailedCategory,
                spendCategories
            };
            return loadSpendResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Spend.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearSpend = (): AppThunk => (dispatch) => {
    dispatch(spendSlice.actions.clearSpendPerOutputArea());
    dispatch(spendSlice.actions.clearSpendPerDetailedCategory());
    dispatch(spendSlice.actions.clearSpendCategories());
    dispatch(spendSlice.actions.clearWeightSpendByProbability());
};

export const resetSpendFilters = (): AppThunk => (dispatch) => {
    dispatch(spendSlice.actions.chooseAllSpendCategories());
    dispatch(spendSlice.actions.clearWeightSpendByProbability());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.location.spendNew.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.location.spendNew.hasErrors;
};

export const selectSpendPerOutputArea = (state: RootState) => {
    return state.customer.tools.location.spendNew.spendPerOutputArea;
};

export const selectSpendPerDetailedCategory = (state: RootState) => {
    return state.customer.tools.location.spendNew.spendPerDetailedCategory;
};

export const selectSpendCategories = (state: RootState) => {
    return state.customer.tools.location.spendNew.spendCategories;
};

export const selectWeightSpendByProbability = (state: RootState) => {
    return state.customer.tools.location.spendNew.weightSpendByProbability;
};

export const selectIsFilterModified = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSpendCategories,
    selectWeightSpendByProbability,
    (isLoading, hasErrors, spendCategories, weightSpendByProbability) => {
        if (isLoading || hasErrors) {
            return false;
        }
        const weightSpendIsModified = !weightSpendByProbability;
        const spendCategoriesIsModified = spendCategories.some(category => !category.isSelected());
        return weightSpendIsModified || spendCategoriesIsModified;
    }
);

export const selectSpendOfWeightedCatchment = createSelector(
    (state: RootState) => selectLocationIsLoading(state),
    (state: RootState) => selectLocationHasErrors(state),
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectTarget(state),
    (isLoading, hasErrors, pinnedLocation, target) => {
        const id = "spend-of-weighted-catchment";
        let label = "Spend of the selected location's weighted catchment area";
        let status = RagIndicatorStatus.Info;
        if (isLoading || hasErrors) {
            return new RagIndicator(id, status, label, "", isLoading, hasErrors);
        }
        if (!pinnedLocation) {
            return new RagIndicator(id, status, label, "No location selected.");
        }
        if (!target?.useSpend) {
            return new RagIndicator(id, RagIndicatorStatus.NoData, "No target set for Spend", "");
        }
        const score = pinnedLocation.retailCentre.getRagScore(ScoreField.Spend);
        switch (score) {
            case 5:
            case 4:
                status = RagIndicatorStatus.Green;
                label = "The weighted spend of the selected location's catchment area aligns strongly with the target value.";
                break;
            case 3:
            case 2:
                status = RagIndicatorStatus.Amber;
                label = "The weighted spend of the selected location's catchment area aligns averagely with the target value.";
                break;
            default:
                status = RagIndicatorStatus.Red;
                label = "The weighted spend of the selected location's catchment area aligns weakly with the target value.";
        }
        return new RagIndicator(id, status, label, "");
    }
);

export const selectSpendAlignmentScore = createSelector(
    (state: RootState) => selectPinnedLocation(state),
    (pinnedLocation) => {
        return pinnedLocation?.retailCentre.spendScore ?? 0;
    }
);

export const selectSpendWithCustomerProfiles = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSpendPerOutputArea,
    (state: RootState) => selectCatchmentCustomerProfiles(state),
    (isLoading, hasErrors, spendPerOutputArea, catchmentCustomerProfiles) => {
        if (isLoading || hasErrors || catchmentCustomerProfiles.isLoading || catchmentCustomerProfiles.hasErrors) {
            return [];
        }

        const aggregatedSpendPerOutputArea = _(spendPerOutputArea)
            .groupBy(spendByOutputArea => spendByOutputArea.outputAreaCode)
            .map((group, key) => {
                return {
                    outputAreaCode: key,
                    likelihoodOfVisiting: group[0].probability,
                    supergroupName: group[0].supergroupName,
                    spendByCategory: group.map(item => ({
                        categoryName: item.categoryName,
                        spend: item.spend,
                        spendPerHead: item.spendPerHead
                    }))
                };
            })
            .value();

        const sortedSpend = aggregatedSpendPerOutputArea.sort((a, b) => stringSortExpression(a.outputAreaCode, b.outputAreaCode, SortDirection.ASC));
        const sortedCustomerProfiles = [...catchmentCustomerProfiles.data].sort((a, b) => stringSortExpression(a.outputAreaCode, b.outputAreaCode, SortDirection.ASC));

        let customerProfilesIndex = 0;
        const spendWithCustomerProfiles = sortedSpend.map((spend) => {
            let relevantCustomerProfile = sortedCustomerProfiles[customerProfilesIndex];

            if (relevantCustomerProfile.outputAreaCode !== spend.outputAreaCode) {
                customerProfilesIndex = sortedCustomerProfiles.findIndex(customerProfile => customerProfile.outputAreaCode === spend.outputAreaCode);
                relevantCustomerProfile = sortedCustomerProfiles[customerProfilesIndex];
            }
            customerProfilesIndex++;
            return {
                ...spend,
                supergroupCode: relevantCustomerProfile?.supergroupCode,
                groupName: relevantCustomerProfile?.groupName,
                subgroupName: relevantCustomerProfile?.subgroupName,
                population: relevantCustomerProfile?.population
            };
        });
        return spendWithCustomerProfiles;
    }
);

export const selectAggregatedSpendByOutputArea = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSpendWithCustomerProfiles,
    selectSpendCategories,
    selectWeightSpendByProbability,
    (isLoading, hasErrors, spendPerOutputArea, spendCategories, weightSpendByProbability) => {
        if (isLoading || hasErrors) {
            return [];
        }
        
        const selectedSpendCategories = spendCategories.filter(spendCategory => spendCategory.isSelected());
        const selectedspendCategoryNames = selectedSpendCategories.map(spendCategory => spendCategory.name);

        return spendPerOutputArea.map(spendPerOA => {
            const filteredSpendByCategory = spendPerOA.spendByCategory.filter(spend => selectedspendCategoryNames.includes(spend.categoryName));
            const weight = weightSpendByProbability ? spendPerOA.likelihoodOfVisiting : 1;
            const weightedFilteredSpendByCategory = filteredSpendByCategory.map(spendByCategory => ({
                categoryName: spendByCategory.categoryName,
                spend: spendByCategory.spend * weight,
                spendPerHead: spendByCategory.spendPerHead * weight
            }));
            return {
                ...spendPerOA,
                spend: weightedFilteredSpendByCategory.reduce((accumulator, spendByOutputArea) => accumulator + spendByOutputArea.spend, 0),
                spendPerHead: weightedFilteredSpendByCategory.reduce((accumulator, spendByOutputArea) => accumulator + spendByOutputArea.spendPerHead, 0),
                spendByCategory: weightedFilteredSpendByCategory
            };
        });
    }
);

export const selectCatchmentAreaYearlySpend = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSpendCategories,
    selectSpendPerDetailedCategory,
    selectWeightSpendByProbability,
    (isLoading, hasErrors, spendCategories, spendPerDetailedCategory, weightSpendByProbability) => {
        if (isLoading || hasErrors) {
            return {
                selectedLocationValue: 0,
                comparatorValue: 0
            };
        }

        const spendCategoryNames = spendCategories.filter(category => category.isSelected()).map(category => category.name);
        
        const comparatorValue = spendPerDetailedCategory
            .filter(spend => spendCategoryNames.includes(spend.spendCategoryName) && spend.isComparator)
            .reduce((total, current) => total + (weightSpendByProbability ? current.weightedSpend : current.unweightedSpend), 0);

        const selectedLocationValue = spendPerDetailedCategory
            .filter(spend => spendCategoryNames.includes(spend.spendCategoryName) && !spend.isComparator)
            .reduce((total, current) => total + (weightSpendByProbability ? current.weightedSpend : current.unweightedSpend), 0);

        return {
            selectedLocationValue,
            comparatorValue
        };
    }
);

export const selectCatchmentAreaSpendPerHead = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectCatchmentAreaYearlySpend,
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectComparatorsByChapter(state),
    (state: RootState) => selectCatchmentPopulation(state),
    (isLoading, hasErrors, catchmentYearlySpend, pinnedLocation, comparatorsByChapter, catchmentPopulation) => {
        if (isLoading || hasErrors || catchmentPopulation.isLoading || catchmentPopulation.hasErrors) {
            return {
                selectedLocationValue: 0,
                comparatorValue: 0
            };
        }
        const selectedLocationCatchmentPopulationData = catchmentPopulation.data
            .find(item => item.retailCentreId === pinnedLocation?.retailCentre.id && (item.isScenario));
        const selectedLocationCatchmentPopulation = selectedLocationCatchmentPopulationData?.population ?? 0;

        const comparatorCatchmentPopulationData = catchmentPopulation.data
            .find(item => item.retailCentreId === comparatorsByChapter?.spend?.retailCentreId && (item.isScenario === comparatorsByChapter.spend.scenarioCatchment));
        const comparatorCatchmentPopulation = comparatorCatchmentPopulationData?.population ?? 0;
        
        const selectedLocationValue = catchmentYearlySpend.selectedLocationValue / selectedLocationCatchmentPopulation;
        const comparatorValue = catchmentYearlySpend.comparatorValue / comparatorCatchmentPopulation;
        return {
            selectedLocationValue,
            comparatorValue
        };
    }
);

export const selectCatchmentAreaSpendByMarketAndProductCategory = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSpendPerDetailedCategory,
    selectWeightSpendByProbability,
    selectSpendCategories,
    (state: RootState) => selectPinnedLocation(state),
    (isLoading, hasErros, spendPerDetailedCategory, weightSpendByProbability, spendCategories, pinnedLocation) => {
        if (isLoading || hasErros) {
            return {
                pinnedLocation: [],
                comparator: []
            };
        }
        
        const spendCategoryNames = spendCategories.filter(category => category.isSelected()).map(category => category.name);
        const filteredSpendByDetailedCategory = spendPerDetailedCategory
            .filter(category => spendCategoryNames.includes(category.spendCategoryName));

        const pinnedLocationGroupedSpendCategories = _(filteredSpendByDetailedCategory)
            .filter(x => x.retailCentreId === pinnedLocation?.retailCentre.id)
            .groupBy(category => category.detailedCategoryName)
            .map((group, key) => ({
                detailedSpendCategory: key,
                spendCategory: group[0].spendCategoryName,
                totalCategorySpend: group.reduce((total, current) => total + (weightSpendByProbability ? current.weightedSpend : current.unweightedSpend), 0),
            }))
            .groupBy(x => x.spendCategory)
            .map((group, key) => ({
                spendCategory: key,
                totalCategorySpend: group.reduce((total, current) => total + current.totalCategorySpend, 0),
                spendByDetailedCategory: group.map(item => ({
                    detailedCategoryName: item.detailedSpendCategory,
                    spend: item.totalCategorySpend
                }))
            }))
            .value()
            .sort((a, b) => stringSortExpression(a.spendCategory, b.spendCategory, SortDirection.ASC));

        const comparatorGroupedSpendCategories = _(filteredSpendByDetailedCategory)
            .filter(x => x.retailCentreId !== pinnedLocation?.retailCentre.id)
            .groupBy(category => category.detailedCategoryName)
            .map((group, key) => ({
                detailedSpendCategory: key,
                spendCategory: group[0].spendCategoryName,
                totalCategorySpend: group.reduce((total, current) => total + (weightSpendByProbability ? current.weightedSpend : current.unweightedSpend), 0),
            }))
            .groupBy(x => x.spendCategory)
            .map((group, key) => ({
                spendCategory: key,
                totalCategorySpend: group.reduce((total, current) => total + current.totalCategorySpend, 0),
                spendByDetailedCategory: group.map(item => ({
                    detailedCategoryName: item.detailedSpendCategory,
                    spend: item.totalCategorySpend
                }))
            }))
            .value()
            .sort((a, b) => stringSortExpression(a.spendCategory, b.spendCategory, SortDirection.ASC));

        return {
            pinnedLocation: pinnedLocationGroupedSpendCategories,
            comparator: comparatorGroupedSpendCategories,
        };
    }
);

export const selectSpendPerHeadAndPopulationPerSupergroup = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectAggregatedSpendByOutputArea,
    (isLoading, hasErrors, spendPerOa) => {
        if (isLoading || hasErrors) {
            return [];
        }

        const spendPerHeadAndPopulation = _(spendPerOa)
            .groupBy(item => item.supergroupCode)
            .map((group, supergroupCode) => {
                const totalSpend = group.reduce((total, current) => total + current.spend, 0);
                const population = group.reduce((total, current) => total + current.population, 0);
                return {
                    supergroupCode: Number(supergroupCode),
                    supergroupName: group[0].supergroupName,
                    spendPerHead: totalSpend / population,
                    population
                };
            })
            .value();

        return spendPerHeadAndPopulation;
    }
);

export enum CustomerProfileType {
    Subgroup,
    Group,
    Supergroup
}

interface CustomerProfilesTreemapRow {
    name: string,
    type: CustomerProfileType,
    supergroupCode?: number,
    parent?: string,
    totalSpend: number,
    spendByCategory: SpendByCategory[]
}

export const selectSpendByCustomerProfile = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectAggregatedSpendByOutputArea,
    selectSpendCategories,
    (isLoading, hasErrors, spendPerOa, spendCategories) => {
        const numberOfSelectedCategories = spendCategories.filter(category => category.isSelected()).length;
        if (isLoading || hasErrors || (numberOfSelectedCategories === 0)) {
            return [];
        }

        const supergroups: CustomerProfilesTreemapRow[] = _(spendPerOa)
            .groupBy(item => item.supergroupCode)
            .map((group, supergroupCode) => {
                const allSpendByCategories: SpendByCategory[] = group.map(item => item.spendByCategory).flat();
                return {
                    name: group[0].supergroupName,
                    type: CustomerProfileType.Supergroup,
                    supergroupCode: Number(supergroupCode),
                    parent: undefined,
                    totalSpend: group.reduce((total, current) => total + current.spend, 0),
                    spendByCategory: _(allSpendByCategories)
                        .groupBy(item => item.categoryName)
                        .map((spendByCategory, categoryName) => {
                            return {
                                categoryName,
                                spend: spendByCategory.reduce((total, current) => total + current.spend, 0)
                            };
                        })
                        .value()
                        .sort((a, b) => numberSortExpression(a.spend, b.spend, SortDirection.DESC))
                };
            })
            .value();

        const groups: CustomerProfilesTreemapRow[] = _(spendPerOa)
            .groupBy(item => item.groupName)
            .map((group, groupName) => {
                const allSpendByCategories: SpendByCategory[] = group.map(item => item.spendByCategory).flat();
                return {
                    name: groupName,
                    type: CustomerProfileType.Group,
                    parent: group[0].supergroupName,
                    totalSpend: group.reduce((total, current) => total + current.spend, 0),
                    spendByCategory: _(allSpendByCategories)
                        .groupBy(item => item.categoryName)
                        .map((spendByCategory, categoryName) => {
                            return {
                                categoryName,
                                spend: spendByCategory.reduce((total, current) => total + current.spend, 0)
                            };
                        })
                        .value()
                        .sort((a, b) => numberSortExpression(a.spend, b.spend, SortDirection.DESC))
                };
            })
            .value();

        const subgroups: CustomerProfilesTreemapRow[] = _(spendPerOa)
            .groupBy(item => item.subgroupName)
            .map((group, subgroupName) => {
                const allSpendByCategories: SpendByCategory[] = group.map(item => item.spendByCategory).flat();
                return {
                    name: subgroupName,
                    type: CustomerProfileType.Subgroup,
                    parent: group[0].groupName,
                    totalSpend: group.reduce((total, current) => total + current.spend, 0),
                    spendByCategory: _(allSpendByCategories)
                        .groupBy(item => item.categoryName)
                        .map((spendByCategory, categoryName) => {
                            return {
                                categoryName,
                                spend: spendByCategory.reduce((total, current) => total + current.spend, 0)
                            };
                        })
                        .value()
                        .sort((a, b) => numberSortExpression(a.spend, b.spend, SortDirection.DESC))
                };
            })
            .value();

        const spendByCustomerProfiles = supergroups.concat(groups, subgroups);

        return spendByCustomerProfiles;
    }
);

export default spendSlice;
