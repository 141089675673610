import React from "react";

import Chapter from "components/customer/insights/Chapter";
import Page from "components/customer/insights/Page";
import Subchapter from "components/customer/insights/Subchapter";

import Filters from "./filters/Filters";

import OverviewSubtitle from "./overview/Subtitle";
import StoreSummary from "./overview/storeSummary/StoreSummary";
import Recommendations from "./overview/recommendations/Recommendations";

import RevenueChapterSubtitle from "./revenue/Subtitle";
import RevenueSubchapterSubtitle from "./revenue/revenue/Subtitle";
import Revenue from "./revenue/revenue/Revenue";
import RankedRevenueGrowthSubtitle from "./revenue/rankedRevenueGrowth/Subtitle";
import RankedRevenueGrowth from "./revenue/rankedRevenueGrowth/RankedRevenueGrowth";
import ProductCategoryMixSubtitle from "./revenue/productCategoryMix/Subtitle";
import ProductCategoryMix from "./revenue/productCategoryMix/ProductCategoryMix";
import ProductCategoryGrowthSubtitle from "./revenue/productCategoryGrowth/Subtitle";
import ProductCategoryGrowth from "./revenue/productCategoryGrowth/ProductCategoryGrowth";

import ProfitSubtitle from "./profit/Subtitle";
import GrossProfitMarginSubtitle from "./profit/grossProfitMargin/Subtitle";
import GrossProfitMargin from "./profit/grossProfitMargin/GrossProfitMargin";
import RankedGrossProfitMarginSubtitle from "./profit/rankedGrossProfitMargin/Subtitle";
import RankedGrossProfitMargin from "./profit/rankedGrossProfitMargin/RankedGrossProfitMargin";
import NetProfitSubtitle from "./profit/netProfit/Subtitle";
import NetProfit from "./profit/netProfit/NetProfit";

import DriversSubtitle from "./drivers/Subtitle";
import StoreSizeSubtitle from "./drivers/storeSize/Subtitle";
import StoreSize from "./drivers/storeSize/StoreSize";
import StaffingSubtitle from "./drivers/staffing/Subtitle";
import Staffing from "./drivers/staffing/Staffing";

import CatchmentSubtitle from "./catchment/Subtitle";
import CustomerProfilesSubtitle from "./catchment/customerProfiles/Subtitle";
import CustomerProfiles from "./catchment/customerProfiles/CustomerProfiles";
import MarketCategorySpendSubtitle from "./catchment/marketCategorySpend/Subtitle";
import MarketCategorySpend from "./catchment/marketCategorySpend/MarketCategorySpend";
import CatchmentAreaDemographicsSubtitle from "./catchment/catchmentAreaDemographics/Subtitle";
import CatchmentAreaDemographics from "./catchment/catchmentAreaDemographics/CatchmentAreaDemographics";

import CompetitionSubtitle from "./competition/Subtitle";
import NearbyCompetitionSubtitle from "./competition/nearbyCompetition/Subtitle";
import NearbyCompetition from "./competition/nearbyCompetition/NearbyCompetition";
//import CannibalisationSubtitle from "./competition/cannibalisation/Subtitle";
import SupplyAndDemandSubtitle from "./competition/supplyAndDemand/Subtitle";
import SupplyAndDemand from "./competition/supplyAndDemand/SupplyAndDemand";
//import MarketShareSubtitle from "./competition/marketShare/Subtitle";

import AreaHealthSubtitle from "./areaHealth/Subtitle";
import OpeningsAndClosuresSubtitle from "./areaHealth/openingsAndClosures/Subtitle";
import OpeningsAndClosures from "./areaHealth/openingsAndClosures/OpeningsAndClosures";
import StoreCategoryBreakdownSubtitle from "./areaHealth/storeCategoryBreakdown/Subtitle";
import StoreCategoryBreakdown from "./areaHealth/storeCategoryBreakdown/StoreCategoryBreakdown";

import FootfallChapterSubtitle from "./footfall/Subtitle";
import FootfallSubchapterSubtitle from "./footfall/footfall/Subtitle";
import Footfall from "./footfall/footfall/Footfall";
import FootfallChangesOverTimeSubtitle from "./footfall/footfallChangesOvertime/Subtitle";
import FootfallChangesOverTime from "./footfall/footfallChangesOvertime/FootfallChangesOverTime";

import { selectUserInfo } from "modules/auth/authSlice";
import { clearFilters, showFilters } from "modules/customer/insights/portfolioNew/filters/filtersSlice";
import {
    clearInsights,
    clearPortfolio,
    loadInsights,
    loadPortfolio,
    selectComparator,
    selectIsSetupComplete,
    selectStore,
    setSubchaptersIds
} from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppDispatch, useAppSelector } from "store";

const OVERVIEW_CHAPTER_ID = "overview";
const STORE_SUMMARY_SUBCHAPTER_ID = `${OVERVIEW_CHAPTER_ID}_store-summary`;
const RECOMMENDATIONS_SUBCHAPTER_ID = `${OVERVIEW_CHAPTER_ID}_recommendations`;

const REVENUE_CHAPTER_ID = "revenue";
const REVENUE_SUBCHAPTER_ID = `${REVENUE_CHAPTER_ID}_revenue`;
const RANKED_REVENUE_GROWTH_SUBCHAPTER_ID = `${REVENUE_CHAPTER_ID}_ranked-revenue-growth`;
const PRODUCT_CATEGORY_MIX_SUBCHAPTER_ID = `${REVENUE_CHAPTER_ID}_product-category-mix`;
const PRODUCT_CATEGORY_GROWTH_SUBCHAPTER_ID = `${REVENUE_CHAPTER_ID}_product-category-growth`;

const PROFIT_CHAPTER_ID = "performance-drivers";
const GROSS_PROFIT_MARGIN_SUBCHAPTER_ID = `${PROFIT_CHAPTER_ID}_gross-profit-margin`;
const RANKED_GROSS_PROFIT_MARGIN_SUBCHAPTER_ID = `${PROFIT_CHAPTER_ID}_ranked-gross-profit-margin`;
const NET_PROFIT_SUBCHAPTER_ID = `${PROFIT_CHAPTER_ID}_net-profit`;

const DRIVERS_CHAPTER_ID = "drivers";
const STORE_SIZE_SUBCHAPTER_ID = `${DRIVERS_CHAPTER_ID}_store-size`;
const STAFFING_SUBCHAPTER_ID = `${DRIVERS_CHAPTER_ID}_staffing`;

const CATCHMENT_CHAPTER_ID = "catchment";
const CUSTOMER_PROFILES_SUBCHAPTER_ID = `${CATCHMENT_CHAPTER_ID}_customer-profiles`;
const MARKET_CATEGORY_SPEND_SUBCHAPTER_ID = `${CATCHMENT_CHAPTER_ID}_market-category-spend`;
const CATCHMENT_AREA_DEMOGRAPHICS_SUBCHAPTER_ID = `${CATCHMENT_CHAPTER_ID}_catchment-area-demographics`;

const COMPETITION_CHAPTER_ID = "competition";
const NEARBY_COMPETITION_SUBCHAPTER_ID = `${COMPETITION_CHAPTER_ID}_nearby-competition`;
//const CANNIBALISATION_SUBCHAPTER_ID = `${COMPETITION_CHAPTER_ID}_cannibalisation`;
const SUPPLY_AND_DEMAND_SUBCHAPTER_ID = `${COMPETITION_CHAPTER_ID}_supply-and-demand`;
//const MARKET_SHARE_SUBCHAPTER_ID = `${COMPETITION_CHAPTER_ID}_market-share`;

const AREA_HEALTH_CHAPTER_ID = "area-health";
const OPENINGS_AND_CLOSURES_SUBCHAPTER_ID = `${AREA_HEALTH_CHAPTER_ID}_openings-and-closures`;
const STORE_CATEGORY_BREAKDOWN_SUBCHAPTER_ID = `${AREA_HEALTH_CHAPTER_ID}_store-category-breakdown`;

const FOOTFALL_CHAPTER_ID = "footfall";
const FOOTFALL_SUBCHAPTER_ID = `${FOOTFALL_CHAPTER_ID}_footfall`;
const FOOTFALL_CHANGES_OVER_TIME_SUBCHAPTER_ID = `${FOOTFALL_CHAPTER_ID}_footfall-changes-over-time`;

const Portfolio: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const isSetupComplete = useAppSelector(selectIsSetupComplete);
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    const overviewChapterRef = React.useRef<HTMLDivElement>(null);
    const storeSummarySubchapterRef = React.useRef<HTMLDivElement>(null);
    const recommendationsSubchapterRef = React.useRef<HTMLDivElement>(null);

    const revenueChapterRef = React.useRef<HTMLDivElement>(null);
    const revenueSubchapterRef = React.useRef<HTMLDivElement>(null);
    const rankedRevenueGrowthSubchapterRef = React.useRef<HTMLDivElement>(null);
    const productCategoryMixSubchapterRef = React.useRef<HTMLDivElement>(null);
    const productCategoryGrowthSubchapterRef = React.useRef<HTMLDivElement>(null);

    const profitChapterRef = React.useRef<HTMLDivElement>(null);
    const grossProfitMarginSubchapterRef = React.useRef<HTMLDivElement>(null);
    const rankedGrossProfitMarginSubchapterRef = React.useRef<HTMLDivElement>(null);
    const netProfitSubchapterRef = React.useRef<HTMLDivElement>(null);

    const driversChapterRef = React.useRef<HTMLDivElement>(null);
    const storeSizeSubchapterRef = React.useRef<HTMLDivElement>(null);
    const staffingSubchapterRef = React.useRef<HTMLDivElement>(null);

    const catchmentChapterRef = React.useRef<HTMLDivElement>(null);
    const customerProfilesSubchapterRef = React.useRef<HTMLDivElement>(null);
    const marketCategorySpendSubchapterRef = React.useRef<HTMLDivElement>(null);
    const catchmentAreaDemographicsSubchapterRef = React.useRef<HTMLDivElement>(null);

    const competitionChapterRef = React.useRef<HTMLDivElement>(null);
    const nearbyCompetitionSubchapterRef = React.useRef<HTMLDivElement>(null);
    //const cannibalisationSubchapterRef = React.useRef<HTMLDivElement>(null);
    const supplyAndDemandSubchapterRef = React.useRef<HTMLDivElement>(null);
    //const marketShareSubchapterRef = React.useRef<HTMLDivElement>(null);

    const areaHealthChapterRef = React.useRef<HTMLDivElement>(null);
    const openingsAndClosuresSubchapterRef = React.useRef<HTMLDivElement>(null);
    const storeCategoryBreakdownSubchapterRef = React.useRef<HTMLDivElement>(null);

    const footfallChapterRef = React.useRef<HTMLDivElement>(null);
    const footfallSubchapterRef = React.useRef<HTMLDivElement>(null);
    const footfallChangesOverTimeSubchapterRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        dispatch(loadPortfolio());
        dispatch(showFilters());
        dispatch(setSubchaptersIds({
            overview: {
                storeSummary: STORE_SUMMARY_SUBCHAPTER_ID,
                recommendations: RECOMMENDATIONS_SUBCHAPTER_ID
            },
            revenue: {
                revenue: REVENUE_SUBCHAPTER_ID,
                rankedRevenueGrowth: RANKED_REVENUE_GROWTH_SUBCHAPTER_ID,
                productCategoryMix: PRODUCT_CATEGORY_MIX_SUBCHAPTER_ID,
                productCategoryGrowth: PRODUCT_CATEGORY_GROWTH_SUBCHAPTER_ID
            },
            profit: {
                grossProfitMargin: GROSS_PROFIT_MARGIN_SUBCHAPTER_ID,
                rankedGrossProfitMargin: RANKED_GROSS_PROFIT_MARGIN_SUBCHAPTER_ID,
                netProfit: NET_PROFIT_SUBCHAPTER_ID
            },
            drivers: {
                storeSize: STORE_SIZE_SUBCHAPTER_ID,
                staffing: STAFFING_SUBCHAPTER_ID
            },
            catchment: {
                customerProfiles: CUSTOMER_PROFILES_SUBCHAPTER_ID,
                marketCategorySpend: MARKET_CATEGORY_SPEND_SUBCHAPTER_ID,
                catchmentAreaDemographics: CATCHMENT_AREA_DEMOGRAPHICS_SUBCHAPTER_ID,
            },
            competition: {
                nearbyCompetition: NEARBY_COMPETITION_SUBCHAPTER_ID,
                //cannibalisation: CANNIBALISATION_SUBCHAPTER_ID,
                supplyAndDemand: SUPPLY_AND_DEMAND_SUBCHAPTER_ID,
                //marketShare: MARKET_SHARE_SUBCHAPTER_ID
            },
            areaHealth: {
                openingsAndClosures: OPENINGS_AND_CLOSURES_SUBCHAPTER_ID,
                storeCategoryBreakdown: STORE_CATEGORY_BREAKDOWN_SUBCHAPTER_ID
            },
            footfall: {
                footfall: FOOTFALL_SUBCHAPTER_ID,
                footfallChangesOverTime: FOOTFALL_CHANGES_OVER_TIME_SUBCHAPTER_ID
            }
        }));
        return () => {
            dispatch(clearPortfolio());
            dispatch(clearFilters());
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (store && comparator) {
            dispatch(loadInsights());
        }
        return () => {
            dispatch(clearInsights());
        };
    }, [dispatch, store, comparator]);

    return (
        <Page
            companyName={userInfo.companyDisplayName}
            title="Portfolio"
            filters={<Filters />}
            isSetupComplete={isSetupComplete}
            dataCy="pages-customer-insights-portfolio"
        >
            <Chapter
                id={OVERVIEW_CHAPTER_ID}
                number={1}
                title="Overview"
                subtitle={<OverviewSubtitle />}
                chapterRef={overviewChapterRef}
                dataCy="overview-chapter"
            >
                <Subchapter
                    id={STORE_SUMMARY_SUBCHAPTER_ID}
                    number={1.1}
                    title="Store summary"
                    subtitle={undefined}
                    subchapterRef={storeSummarySubchapterRef}
                    dataCy="store-summary-subchapter"
                >
                    <StoreSummary />
                </Subchapter>
                <Subchapter
                    id={RECOMMENDATIONS_SUBCHAPTER_ID}
                    number={1.2}
                    title="Recommendations"
                    subtitle={undefined}
                    subchapterRef={recommendationsSubchapterRef}
                    dataCy="recommendations-subchapter"
                >
                    <Recommendations />
                </Subchapter>
            </Chapter>
            <Chapter
                id={REVENUE_CHAPTER_ID}
                number={2}
                title="Revenue"
                subtitle={<RevenueChapterSubtitle />}
                chapterRef={revenueChapterRef}
                dataCy="revenue-chapter"
            >
                <Subchapter
                    id={REVENUE_SUBCHAPTER_ID}
                    number={2.1}
                    title="Revenue"
                    subtitle={<RevenueSubchapterSubtitle />}
                    subchapterRef={revenueSubchapterRef}
                    dataCy="revenue-subchapter"
                >
                    <Revenue/>
                </Subchapter>
                <Subchapter
                    id={RANKED_REVENUE_GROWTH_SUBCHAPTER_ID}
                    number={2.2}
                    title="Ranked revenue growth"
                    subtitle={<RankedRevenueGrowthSubtitle />}
                    subchapterRef={rankedRevenueGrowthSubchapterRef}
                    dataCy="ranked-revenue-growth-subchapter"
                >
                    <RankedRevenueGrowth/>
                </Subchapter>
                <Subchapter
                    id={PRODUCT_CATEGORY_MIX_SUBCHAPTER_ID}
                    number={2.3}
                    title="Product category mix"
                    subtitle={<ProductCategoryMixSubtitle />}
                    subchapterRef={productCategoryMixSubchapterRef}
                    dataCy="product-category-mix-subchapter"
                >
                    <ProductCategoryMix />
                </Subchapter>
                <Subchapter
                    id={PRODUCT_CATEGORY_GROWTH_SUBCHAPTER_ID}
                    number={2.4}
                    title="Product category growth"
                    subtitle={<ProductCategoryGrowthSubtitle />}
                    subchapterRef={productCategoryGrowthSubchapterRef}
                    dataCy="product-category-growth-subchapter"
                >
                    <ProductCategoryGrowth />
                </Subchapter>
            </Chapter>
            <Chapter
                id={PROFIT_CHAPTER_ID}
                number={3}
                title="Profit"
                subtitle={<ProfitSubtitle />}
                chapterRef={profitChapterRef}
                dataCy="profit-chapter"
            >
                <Subchapter
                    id={GROSS_PROFIT_MARGIN_SUBCHAPTER_ID}
                    number={3.1}
                    title="Gross profit margin"
                    subtitle={<GrossProfitMarginSubtitle />}
                    subchapterRef={grossProfitMarginSubchapterRef}
                    dataCy="gross-profit-margin-subchapter"
                >
                    <GrossProfitMargin />
                </Subchapter>
                <Subchapter
                    id={RANKED_GROSS_PROFIT_MARGIN_SUBCHAPTER_ID}
                    number={3.2}
                    title="Ranked gross profit margin"
                    subtitle={<RankedGrossProfitMarginSubtitle />}
                    subchapterRef={rankedGrossProfitMarginSubchapterRef}
                    dataCy="ranked-gross-profit-margin-subchapter"
                >
                    <RankedGrossProfitMargin />
                </Subchapter>
                <Subchapter
                    id={NET_PROFIT_SUBCHAPTER_ID}
                    number={3.3}
                    title="Net profit"
                    subtitle={<NetProfitSubtitle />}
                    subchapterRef={netProfitSubchapterRef}
                    dataCy="net-profit-subchapter"
                >
                    <NetProfit />
                </Subchapter>
            </Chapter>
            <Chapter
                id={DRIVERS_CHAPTER_ID}
                number={4}
                title="Drivers"
                subtitle={<DriversSubtitle />}
                chapterRef={driversChapterRef}
                dataCy="drivers-chapter"
            >
                <Subchapter
                    id={STORE_SIZE_SUBCHAPTER_ID}
                    number={4.1}
                    title="Store size"
                    subtitle={<StoreSizeSubtitle />}
                    subchapterRef={storeSizeSubchapterRef}
                    dataCy="store-size-subchapter"
                >
                    <StoreSize />
                </Subchapter>
                <Subchapter
                    id={STAFFING_SUBCHAPTER_ID}
                    number={4.2}
                    title="Staffing"
                    subtitle={<StaffingSubtitle />}
                    subchapterRef={staffingSubchapterRef}
                    dataCy="staffing-subchapter"
                >
                    <Staffing />
                </Subchapter>
            </Chapter>
            <Chapter
                id={CATCHMENT_CHAPTER_ID}
                number={5}
                title="Catchment"
                subtitle={<CatchmentSubtitle />}
                chapterRef={catchmentChapterRef}
                dataCy="catchment-chapter"
            >
                <Subchapter
                    id={CUSTOMER_PROFILES_SUBCHAPTER_ID}
                    number={5.1}
                    title="Customer profiles"
                    subtitle={<CustomerProfilesSubtitle />}
                    subchapterRef={customerProfilesSubchapterRef}
                    dataCy="customer-profiles-subchapter"
                >
                    <CustomerProfiles />
                </Subchapter>
                <Subchapter
                    id={MARKET_CATEGORY_SPEND_SUBCHAPTER_ID}
                    number={5.2}
                    title="Market category spend"
                    subtitle={<MarketCategorySpendSubtitle />}
                    subchapterRef={marketCategorySpendSubchapterRef}
                    dataCy="market-category-spend-subchapter"
                >
                    <MarketCategorySpend />
                </Subchapter>
                <Subchapter
                    id={CATCHMENT_AREA_DEMOGRAPHICS_SUBCHAPTER_ID}
                    number={5.3}
                    title="Catchment area demographics"
                    subtitle={<CatchmentAreaDemographicsSubtitle />}
                    subchapterRef={catchmentAreaDemographicsSubchapterRef}
                    dataCy="catchment-area-demographics-subchapter"
                >
                    <CatchmentAreaDemographics />
                </Subchapter>
            </Chapter>
            <Chapter
                id={COMPETITION_CHAPTER_ID}
                number={6}
                title="Competition"
                subtitle={<CompetitionSubtitle />}
                chapterRef={competitionChapterRef}
                dataCy="competition-chapter"
            >
                <Subchapter
                    id={NEARBY_COMPETITION_SUBCHAPTER_ID}
                    number={6.1}
                    title="Nearby competition"
                    subtitle={<NearbyCompetitionSubtitle />}
                    subchapterRef={nearbyCompetitionSubchapterRef}
                    dataCy="nearby-competition-subchapter"
                >
                    <NearbyCompetition />
                </Subchapter>
                {/*<Subchapter*/}
                {/*    id={CANNIBALISATION_SUBCHAPTER_ID}*/}
                {/*    number={6.2}*/}
                {/*    title="Cannibalisation"*/}
                {/*    subtitle={<CannibalisationSubtitle />}*/}
                {/*    subchapterRef={cannibalisationSubchapterRef}*/}
                {/*    dataCy="cannibalisation-subchapter"*/}
                {/*>*/}
                {/*    <p>Cannibalisation</p>*/}
                {/*</Subchapter>*/}
                <Subchapter
                    id={SUPPLY_AND_DEMAND_SUBCHAPTER_ID}
                    number={6.2}
                    title="Supply and demand"
                    subtitle={<SupplyAndDemandSubtitle />}
                    subchapterRef={supplyAndDemandSubchapterRef}
                    dataCy="supply-and-demand-subchapter"
                >
                    <SupplyAndDemand />
                </Subchapter>
                {/*<Subchapter*/}
                {/*    id={MARKET_SHARE_SUBCHAPTER_ID}*/}
                {/*    number={6.4}*/}
                {/*    title="Market share"*/}
                {/*    subtitle={<MarketShareSubtitle />}*/}
                {/*    subchapterRef={marketShareSubchapterRef}*/}
                {/*    dataCy="market-share-subchapter"*/}
                {/*>*/}
                {/*    <p>MarketShare</p>*/}
                {/*</Subchapter>*/}
            </Chapter>
            <Chapter
                id={AREA_HEALTH_CHAPTER_ID}
                number={7}
                title="Area health"
                subtitle={<AreaHealthSubtitle />}
                chapterRef={areaHealthChapterRef}
                dataCy="area-health-chapter"
            >
                <Subchapter
                    id={OPENINGS_AND_CLOSURES_SUBCHAPTER_ID}
                    number={7.1}
                    title="Openings and closures"
                    subtitle={<OpeningsAndClosuresSubtitle />}
                    subchapterRef={openingsAndClosuresSubchapterRef}
                    dataCy="openings-and-closures-subchapter"
                >
                    <OpeningsAndClosures />
                </Subchapter>
                <Subchapter
                    id={STORE_CATEGORY_BREAKDOWN_SUBCHAPTER_ID}
                    number={7.2}
                    title="Store category breakdown"
                    subtitle={<StoreCategoryBreakdownSubtitle />}
                    subchapterRef={storeCategoryBreakdownSubchapterRef}
                    dataCy="store-category-breakdown-subchapter"
                >
                    <StoreCategoryBreakdown />
                </Subchapter>
            </Chapter>
            <Chapter
                id={FOOTFALL_CHAPTER_ID}
                number={8}
                title="Footfall"
                subtitle={<FootfallChapterSubtitle />}
                chapterRef={footfallChapterRef}
                dataCy="footfall-chapter"
            >
                <Subchapter
                    id={FOOTFALL_SUBCHAPTER_ID}
                    number={8.1}
                    title="Footfall level"
                    subtitle={<FootfallSubchapterSubtitle />}
                    subchapterRef={footfallSubchapterRef}
                    dataCy="footfall-subchapter"
                >
                    <Footfall />
                </Subchapter>
                <Subchapter
                    id={FOOTFALL_CHANGES_OVER_TIME_SUBCHAPTER_ID}
                    number={8.2}
                    title="Footfall changes over time"
                    subtitle={<FootfallChangesOverTimeSubtitle />}
                    subchapterRef={footfallChangesOverTimeSubchapterRef}
                    dataCy="footfall-changes-over-time-subchapter"
                >
                    <FootfallChangesOverTime />
                </Subchapter>
            </Chapter>
        </Page>
    );
};

export default Portfolio;
