import React from "react";

import PinDropRoundedIcon from "@material-ui/icons/PinDropRounded";

import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppSelector } from "store";

interface Tool {
    name: string,
    icon: React.ReactElement,
    url: string,
    image: string,
    alt: string,
    isAvailable: boolean,
    isAvailableOnMobile: boolean
}

export const useTools = (): Tool[] => {
    const featureFlags = useAppSelector(selectFeatureFlags);

    return [{
        name: "Location",
        icon: <PinDropRoundedIcon />,
        url: "/tools/location",
        image: "/content/customer/tools/location.png",
        alt: "location tool",
        isAvailable: featureFlags.enableCustomerToolsLocationDesktop,
        isAvailableOnMobile: featureFlags.enableCustomerToolsLocationMobile
    }];
};
