import { combineReducers } from "redux";

import types from "./types";

const users = (state = [], action) => {
    switch (action.type) {
        case types.GET_USERS_SUCCESS:
            return action.payload.users;
        case types.GET_USERS_FAILURE:
            return [];
        default:
            return state;
    }
};

const licensesEmpty = {
    active: 0,
    remaining: 0
};

const licenses = (state = licensesEmpty, action) => {
    switch (action.type) {
        case types.SET_LICENSES:
            return {
                ...state,
                active: action.payload.active,
                remaining: action.payload.remaining
            };
        case types.CLEAR_LICENSES:
            return licensesEmpty;
        default:
            return state;
    }
};

const userEmpty = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "Viewer",
    errors: {
        firstName: "",
        lastName: "",
        email: "",
        role: ""
    }
};

const user = (state = userEmpty, action) => {
    switch (action.type) {
        case types.SET_USER:
            return action.payload.user;
        case types.CLEAR_USER:
            return { ...userEmpty, role: state.role };
        default:
            return state;
    }
};

const recentlyAddedUsers = (state = [], action) => {
    switch (action.type) {
        case types.SET_RECENTLY_ADDED_USERS:
            return action.payload.users;
        case types.CLEAR_RECENTLY_ADDED_USERS:
            return [];
        default:
            return state;
    }
};

const addUserVisibilityEmpty = {
    isVisible: false
};

const addUserVisibility = (state = addUserVisibilityEmpty, action) => {
    switch (action.type) {
        case types.SHOW_ADD_USER:
            return { ...state, isVisible: true };
        case types.HIDE_ADD_USER:
            return addUserVisibilityEmpty;
        default:
            return state;
    }
};

const editUserVisibilityEmpty = {
    isVisible: false,
    id: ""
};

const editUserVisibility = (state = editUserVisibilityEmpty, action) => {
    switch (action.type) {
        case types.SHOW_EDIT_USER:
            return { ...state, isVisible: true, id: action.payload.id };
        case types.HIDE_EDIT_USER:
            return editUserVisibilityEmpty;
        default:
            return state;
    }
};

const deleteUserVisibilityEmpty = {
    isVisible: false,
    id: ""
};

const deleteUserVisibility = (state = deleteUserVisibilityEmpty, action) => {
    switch (action.type) {
        case types.SHOW_DELETE_USER:
            return { ...state, isVisible: true, id: action.payload.id };
        case types.HIDE_DELETE_USER:
            return deleteUserVisibilityEmpty;
        default:
            return state;
    }
};

const filterEmpty = {
    nameOrEmail: ""
};

const filter = (state = filterEmpty, action) => {
    switch (action.type) {
        case types.SET_FILTER:
            return action.payload.filter;
        default:
            return state;
    }
};

const sortEmpty = {
    field: "name",
    direction: "asc"
};

const sort = (state = sortEmpty, action) => {
    switch (action.type) {
        case types.SET_SORT:
            return action.payload.sort;
        default:
            return state;
    }
};

const reducer = combineReducers({
    users,
    licenses,
    user,
    recentlyAddedUsers,
    addUserVisibility,
    editUserVisibility,
    deleteUserVisibility,
    filter,
    sort
});

export default reducer;
