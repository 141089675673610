import { createSelector } from "reselect";

const usersSelector = (state) => {
    return state.customer.account.users.users;
};

const filter = (state) => {
    return state.customer.account.users.filter;
};

const sort = (state) => {
    return state.customer.account.users.sort;
};

const users = createSelector(
    usersSelector,
    filter,
    sort,
    (users, filter, sort) => {
        const nameOrEmailFilter = filter.nameOrEmail.toLowerCase();
        const filteredUsers = users.filter(user =>
            user.firstName.toLowerCase().includes(nameOrEmailFilter)
            || user.lastName.toLowerCase().includes(nameOrEmailFilter)
            || user.email.toLowerCase().includes(nameOrEmailFilter));

        const sortExpression = (itemA, itemB, direction) => {
            return direction === "asc"
                ? itemA.toLowerCase().localeCompare(itemB.toLowerCase())
                : itemB.toLowerCase().localeCompare(itemA.toLowerCase()); //desc
        };
        const sortedUsers = filteredUsers.sort((userA, userB) => {
            return (sort.field === "name" && sortExpression(userA.firstName, userB.firstName, sort.direction))
                || (sort.field === "email" && sortExpression(userA.email, userB.email, sort.direction))
                || (sort.field === "role" && sortExpression(userA.role, userB.role, sort.direction));
        });

        return sortedUsers;
    }
);

const licenses = (state) => {
    return state.customer.account.users.licenses;
};

const user = (state) => {
    return state.customer.account.users.user;
};

const recentlyAddedUsers = (state) => {
    return state.customer.account.users.recentlyAddedUsers;
};

const addUserVisibility = (state) => {
    return state.customer.account.users.addUserVisibility;
};

const editUserVisibility = (state) => {
    return state.customer.account.users.editUserVisibility;
};

const deleteUserVisibility = (state) => {
    return state.customer.account.users.deleteUserVisibility;
};

const selectors = {
    users,
    licenses,
    user,
    recentlyAddedUsers,
    addUserVisibility,
    editUserVisibility,
    deleteUserVisibility,
    filter,
    sort
};

export default selectors;
