import React from "react";

import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import LaunchRoundedIcon from "@material-ui/icons/LaunchRounded";
import { withStyles } from "@material-ui/core/styles";

import { ReactComponent as PoweredByStripe } from "assets/images/powered-by-stripe.svg";

import { useAppDispatch, useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import { createCustomerPortalSession } from "modules/customer/account/billing/billingSlice";

const StyledCard = withStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        flexGrow: 1
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        "&:last-child": {
            padding: theme.spacing(0)
        }
    }
}))(CardContent);

const StyledGridItem = withStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "flex-end"
    }
}))(Grid);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const BillingInformation: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);

    const handleUpdateInformationInStripeClick = () => {
        dispatch(createCustomerPortalSession());
    };

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Box color="text.disabled">
                <Typography variant="subtitle1" component="div" gutterBottom>
                    Billing information
                </Typography>
            </Box>
            <StyledCard elevation={0}>
                <StyledCardContent>
                    <Grid container>
                        <Grid item xs={7}>
                            <Typography variant="h4" component="div" color="textSecondary" gutterBottom>
                                Your billing information is securely hosted in Stripe
                            </Typography>
                            <br />
                            <Typography variant="subtitle1" component="div" color="textSecondary">
                                Visit Stripe to change payment method, billing address, contact name, email address and
                                view invoice history
                            </Typography>
                            <br />
                            <StyledButton
                                variant="text"
                                size="medium"
                                color="default"
                                disableElevation
                                startIcon={<LaunchRoundedIcon />}
                                disabled={userInfo.isAppAdmin}
                                onClick={handleUpdateInformationInStripeClick}
                            >
                                Update information in Stripe
                            </StyledButton>
                        </Grid>
                        <StyledGridItem item xs={5}>
                            <PoweredByStripe />
                        </StyledGridItem>
                    </Grid>
                </StyledCardContent>
            </StyledCard>
        </Box>
    );
};

export default BillingInformation;
