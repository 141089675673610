import React from "react";

import { Grid } from "@material-ui/core";

import CatchmentAreaSpendByMarketAndProductCategory from "./CatchmentAreaSpendByMarketAndProductCategory";
import CatchmentAreaSpendPerHead from "./CatchmentAreaSpendPerHead";
import CatchmentAreaYearlySpend from "./CatchmentAreaYearlySpend";

const TotalSpend: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CatchmentAreaYearlySpend />
            </Grid>
            <Grid item xs={12}>
                <CatchmentAreaSpendPerHead />
            </Grid>
            <Grid item xs={12}>
                <CatchmentAreaSpendByMarketAndProductCategory />
            </Grid>
        </Grid>
    );
};

export default TotalSpend;
