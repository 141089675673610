import { ResultSet } from "@cubejs-client/core";
import { createAppAsyncThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { Store } from "modules/customer/insights/portfolioNew/store";
import { DateTime } from "luxon";

export class YearlyCosts {
    public readonly storeID: string;
    // public readonly date: DateTime;
    public readonly propertyCosts: number;
    public readonly payrollCosts: number;
    public readonly otherCosts: number;

    constructor(
        storeID: string,
        // date: DateTime,
        propertyCosts: number,
        payrollCosts: number,
        otherCosts: number
    ) {
        this.storeID = storeID;
        // this.date = date;
        this.propertyCosts = propertyCosts;
        this.payrollCosts = payrollCosts;
        this.otherCosts = otherCosts;
    }
}


export const loadYearlyCosts =  createAppAsyncThunk<
    YearlyCosts[],
    {selectedStore: Store | undefined, comparatorStores: Store[] | undefined, costReferenceDate: DateTime}
    >(
    "customer/insights/portfolio/loadYearlyCosts",
    async ({selectedStore, comparatorStores, costReferenceDate}, thunkAPI) => {
        try {
            if (!selectedStore || !comparatorStores) {
                return [];
            }

            const comparatorStoreIDs = comparatorStores.map(store => store.id);
            const selectedAndComparatorStoreIDs = [selectedStore.id].concat(...comparatorStoreIDs);
            
            const priorTwelveMonthsToReferenceDate = costReferenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf("day");

            const query = {
                measures: ["F_Cost.SumPropertyCost", "F_Cost.SumPayrollCost", "F_Cost.SumOtherCost"],
                timeDimensions: [{
                    dimension: "D_Date.Date",
                    dateRange: [priorTwelveMonthsToReferenceDate, costReferenceDate.endOf('day')]
                }],
                dimensions: ["D_Store.StoreNaturalID"],
                filters: [{
                    member: "D_Store.StoreNaturalID",
                    operator: "equals",
                    values: selectedAndComparatorStoreIDs
                }]
            };

            const resultSet = await thunkAPI.dispatch(cubeLoad(query)) as unknown as ResultSet;
            return resultSet.rawData().map(row => 
                new YearlyCosts(
                    row["D_Store.StoreNaturalID"],
                    row["F_Cost.SumPropertyCost"],
                    row["F_Cost.SumPayrollCost"],
                    row["F_Cost.SumOtherCost"]
                )
            );
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading YearlyCosts.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
