import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Divider, MenuItem, TextField, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { makeStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";

import { operations, selectors } from "modules/customer/account/users";

const useStyles = makeStyles(theme => ({
    input: {
        backgroundColor: theme.palette.quaternary.main
    },
    checkIcon: {
        color: theme.palette.tertiary.main
    }
}));

const AddUser = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const licenses = useSelector(state => selectors.licenses(state));
    const user = useSelector(state => selectors.user(state));
    const recentlyAddedUsers = useSelector(state => selectors.recentlyAddedUsers(state));
    const addUserVisibility = useSelector(state => selectors.addUserVisibility(state));
    const show = addUserVisibility.isVisible;

    const handleAddUserClick = () => {
        dispatch(operations.showAddUser());
    };

    const handleSubmitClick = () => {
        dispatch(operations.addUser());
    };

    const handleCancelClick = () => {
        dispatch(operations.hideAddUser());
        dispatch(operations.clearUser());
    };

    const handleFirstNameChange = (event) => {
        const firstName = event.target.value;
        const newUser = { ...user, firstName, errors: { ...user.errors, firstName: "" } };
        dispatch(operations.setUser(newUser));
    };

    const handleLastNameChange = (event) => {
        const lastName = event.target.value;
        const newUser = { ...user, lastName, errors: { ...user.errors, lastName: "" } };
        dispatch(operations.setUser(newUser));
    };

    const handleEmailChange = (event) => {
        const email = event.target.value;
        const newUser = { ...user, email, errors: { ...user.errors, email: "" } };
        dispatch(operations.setUser(newUser));
    };

    const handleRoleChange = (event) => {
        const role = event.target.value;
        const newUser = { ...user, role, errors: { ...user.errors, role: "" } };
        dispatch(operations.setUser(newUser));
    };

    return (
        <>
            <Button
                variant="contained"
                size="medium"
                color="secondary"
                startIcon={<AddIcon />}
                disabled={licenses.remaining === 0}
                onClick={handleAddUserClick}
                disableElevation
                fullWidth
            >
                Add user
            </Button>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <>
                        <Typography variant="h5" component="div" gutterBottom>Add user</Typography>
                        <Typography variant="subtitle1" component="div">Give your teammates access to Dash</Typography>
                    </>
                }
                content={
                    <>
                        <TextField
                            label="First name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={user.firstName}
                            onChange={handleFirstNameChange}
                            error={!!user.errors.firstName}
                            helperText={user.errors.firstName}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                        <TextField
                            label="Last name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={user.lastName}
                            onChange={handleLastNameChange}
                            error={!!user.errors.lastName}
                            helperText={user.errors.lastName}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                        <TextField
                            label="Email"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={user.email}
                            onChange={handleEmailChange}
                            error={!!user.errors.email}
                            helperText={user.errors.email}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                        <TextField
                            label="Role"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            select
                            value={user.role}
                            onChange={handleRoleChange}
                            error={!!user.errors.role}
                            helperText={user.errors.role}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        >
                            <MenuItem value="Admin">Admin</MenuItem>
                            <MenuItem value="Editor">Editor</MenuItem>
                            <MenuItem value="Viewer">Viewer</MenuItem>
                        </TextField>
                        {recentlyAddedUsers.length > 0 && (
                            <Box paddingTop={2}>
                                <Divider />
                            </Box>
                        )}
                        {recentlyAddedUsers.map((user, index) =>
                            <Box key={index} display="flex" alignItems="center" paddingTop={1}>
                                <CheckCircleOutlineIcon fontSize="small" className={classes.checkIcon} />
                                &nbsp;&nbsp;
                                <Typography variant="subtitle2" component="div">{user.email}</Typography>
                                &nbsp;&nbsp;&nbsp;
                                <Box color="text.disabled">
                                    <Typography variant="subtitle2" color="inherit" component="div">{user.role}</Typography>
                                </Box>
                            </Box>
                        )}
                    </>
                }
                actions={
                    <>
                        <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            disableElevation
                            onClick={handleSubmitClick}
                        >
                            Add user
                        </Button>
                    </>
                }
            />
        </>
    );
};

export default AddUser;
