import React from "react";

// import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import StoreRoundedIcon from "@material-ui/icons/StoreRounded";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";

interface Insight {
    name: string,
    icon: React.ReactElement,
    url: string,
    image: string,
    alt: string,
    isAvailable: boolean,
    isAvailableOnMobile: boolean
}

export const useInsights = (): Insight[] => {
    return [{
        name: "Performance",
        icon: <TrendingUpRoundedIcon />,
        url: "/insights/performance",
        image: "/content/customer/insights/performance.png",
        alt: "performance insight",
        isAvailable: true,
        isAvailableOnMobile: true
    }, {
        name: "Portfolio",
        icon: <StoreRoundedIcon />,
        url: "/insights/portfolio",
        image: "/content/customer/insights/portfolio.png",
        alt: "portfolio insight",
        isAvailable: true,
        isAvailableOnMobile: false
        //}, {
        //    name: "Range",
        //    icon: <ShoppingBasketRoundedIcon />,
        //    url: "/insights/range",
        //    image: "/content/customer/insights/range.png",
        //    alt: "range insight",
        //    isAvailable: false,
        //    isAvailableOnMobile: false
    }];
};
