import React from "react";

import { Checkbox, Grid, ListItemText, MenuItem, TextField, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { selectCandidateTargetSpendCategories, setCandidateTargetSpendCategories } from "modules/customer/tools/location/filters/filtersSlice";
import { selectSpendCategories } from "modules/customer/tools/location/locationSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledGridContainer = withStyles(theme => ({
    root: {
        margin: 0,
        width: "100%",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5)
    }
}))(Grid);

const StyledGridItem = withStyles(theme => ({
    root: {
        color: (props: any) => props["data-disabled"] ? theme.palette.text.disabled : theme.palette.text.secondary
    }
}))(Grid);

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        },
        "& .MuiSelect-root": {
            textAlign: "left"
        }
    },
}))(TextField);

const useStyles = makeStyles(theme => ({
    paper: {
        maxHeight: "70vh",
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}));

interface SpendCategoriesProps {
    disabled: boolean
}

const SpendCategories: React.FC<SpendCategoriesProps> = (props) => {
    const { disabled } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const spendCategories = useAppSelector(selectSpendCategories);
    const candidateTargetSpendCategories = useAppSelector(selectCandidateTargetSpendCategories);
    const candidateTargetSpendCategoriesIds = candidateTargetSpendCategories.map(spendCategory => spendCategory.id);

    const handleSpendCategoriesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const ids = event.target.value as number[];
        const newCandidateTargetSpendCategories = spendCategories.filter(spendCategory => ids.includes(spendCategory.id));
        dispatch(setCandidateTargetSpendCategories(newCandidateTargetSpendCategories));
    };

    return (
        <StyledGridContainer container spacing={4} alignItems="center">
            <StyledGridItem item xs={3} data-disabled={disabled}>
                <Typography variant="subtitle1" color={disabled ? "inherit" : "textPrimary"} gutterBottom>
                    Market categories
                </Typography>
                <Typography variant="body2" color={disabled ? "inherit" : "textSecondary"}>
                    What type of products will your proposed store sell? Dash will only factor in the selected markets
                    when aligning with your desired spend.
                </Typography>
            </StyledGridItem>
            <StyledGridItem item xs={6} align="center" data-disabled={disabled}>
                <StyledTextField
                    variant="outlined"
                    size="small"
                    color="secondary"
                    margin="normal"
                    select
                    SelectProps={{
                        multiple: true,
                        MenuProps: {
                            variant: "menu",
                            classes: { paper: classes.paper }
                        },
                        renderValue: (value) => {
                            const ids = value as number[];
                            const categories = spendCategories
                                .filter(spendCategory => ids.includes(spendCategory.id))
                                .map(spendCategory => spendCategory.name)
                                .join(", ");
                            return `${ids.length} selected (${categories})`;
                        }
                    }}
                    value={candidateTargetSpendCategoriesIds}
                    onChange={handleSpendCategoriesChange}
                    disabled={disabled}
                    fullWidth
                >
                    {spendCategories.map((spendCategory) => (
                        <MenuItem key={spendCategory.id} value={spendCategory.id}>
                            <Checkbox checked={candidateTargetSpendCategoriesIds.includes(spendCategory.id)} />
                            <ListItemText primary={spendCategory.name} />
                        </MenuItem>
                    ))}
                </StyledTextField>
            </StyledGridItem>
        </StyledGridContainer>
    );
};

export default SpendCategories;
