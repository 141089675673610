import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";

export class LoggerService {
    private readonly applicationInsights?: ApplicationInsights;

    constructor(applicationInsightsInstrumentationKey: string) {
        if (applicationInsightsInstrumentationKey) {
            this.applicationInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: applicationInsightsInstrumentationKey
                }
            });
            this.applicationInsights.loadAppInsights();
            this.applicationInsights.addTelemetryInitializer((telemetryItem) => {
                telemetryItem.tags = telemetryItem.tags || [];
                telemetryItem.tags["ai.cloud.role"] = "DashSpa";
            });
        }
    }

    private log(message: string, customDimensions: any, severityLevel: SeverityLevel) {
        console.log(message, customDimensions, severityLevel);
        if (this.applicationInsights) {
            this.applicationInsights.trackTrace({
                message,
                severityLevel,
                properties: customDimensions
            });
        }
    }

    debug(message: string, customDimensions: any) {
        this.log(message, customDimensions, SeverityLevel.Verbose);
    }

    information(message: string, customDimensions: any) {
        this.log(message, customDimensions, SeverityLevel.Information);
    }

    warning(message: string, customDimensions: any) {
        this.log(message, customDimensions, SeverityLevel.Warning);
    }

    error(message: string, customDimensions: any) {
        this.log(message, customDimensions, SeverityLevel.Error);
    }

    critical(message: string, customDimensions: any) {
        this.log(message, customDimensions, SeverityLevel.Critical);
    }
}
