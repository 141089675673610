import types from "./types";

const getUsersSuccess = (users) => ({
    type: types.GET_USERS_SUCCESS,
    payload: {
        users
    }
});

const getUsersFailure = () => ({
    type: types.GET_USERS_FAILURE
});

const setLicenses = (active, remaining) => ({
    type: types.SET_LICENSES,
    payload: {
        active,
        remaining
    }
});

const clearLicenses = () => ({
    type: types.CLEAR_LICENSES
});

const setUser = (user) => ({
    type: types.SET_USER,
    payload: {
        user
    }
});

const clearUser = () => ({
    type: types.CLEAR_USER
});

const setRecentlyAddedUsers = (users) => ({
    type: types.SET_RECENTLY_ADDED_USERS,
    payload: {
        users
    }
});

const clearRecentlyAddedUsers = () => ({
    type: types.CLEAR_RECENTLY_ADDED_USERS
});

const showAddUser = () => ({
    type: types.SHOW_ADD_USER
});

const hideAddUser = () => ({
    type: types.HIDE_ADD_USER
});

const showEditUser = (id) => ({
    type: types.SHOW_EDIT_USER,
    payload: {
        id
    }
});

const hideEditUser = () => ({
    type: types.HIDE_EDIT_USER
});

const showDeleteUser = (id) => ({
    type: types.SHOW_DELETE_USER,
    payload: {
        id
    }
});

const hideDeleteUser = () => ({
    type: types.HIDE_DELETE_USER
});

const setFilter = (filter) => ({
    type: types.SET_FILTER,
    payload: {
        filter
    }
});

const setSort = (sort) => ({
    type: types.SET_SORT,
    payload: {
        sort
    }
});

const actions = {
    getUsersSuccess,
    getUsersFailure,
    setLicenses,
    clearLicenses,
    setUser,
    clearUser,
    setRecentlyAddedUsers,
    clearRecentlyAddedUsers,
    showAddUser,
    hideAddUser,
    showEditUser,
    hideEditUser,
    showDeleteUser,
    hideDeleteUser,
    setFilter,
    setSort
};

export default actions;
